import {AfterViewInit, Directive, ElementRef, OnDestroy} from '@angular/core';

import {FixedHeaderService} from '../services/fixed-header.service';

@Directive({
  selector: '[wStickyArea]',
  standalone: true,
})
export class StickyAreaDirective implements AfterViewInit, OnDestroy {
  constructor(
    private elementRef: ElementRef<HTMLElement>,
    private fixedHeader: FixedHeaderService,
  ) {}

  ngAfterViewInit() {
    this.fixedHeader.add(this.elementRef.nativeElement);
  }

  ngOnDestroy() {
    this.fixedHeader.remove(this.elementRef.nativeElement);
  }
}
