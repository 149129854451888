import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

import {OemService} from '../../modules/oem/oem.service';

@Component({
  selector: 'w-app-header-oem-logo',
  templateUrl: './app-header-oem-logo.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class AppHeaderOemLogoComponent {
  @Input() height = 16;

  constructor(private oemService: OemService) {}

  get oemLogo(): string {
    return this.oemService.appLogoSmall ?? this.oemService.appLogo;
  }

  get oemCompany(): string {
    return this.oemService.appName;
  }
}
