import {Directive, ElementRef, Input, OnChanges, OnDestroy, OnInit, Optional} from '@angular/core';
import {Subscription} from 'rxjs';

import {isElementVisible} from '../../utils/dom/is-element-visible';
import {WSimpleChanges} from '../../types/angular';
import {toggleEvent} from '../../utils/dom/toggle-event';
import {Position, PositionObject} from '../../types';
import {parseTextPosition} from '../../utils/parse-text-position';
import {getPositionCoordsFromRect} from '../../utils/get-position-coords-from-rect';

import {KeyboardNavigationItemConfig} from './keyboard-navigation.types';
import {KeyboardNavigationService} from './keyboard-navigation.service';
import {KeyboardNavigationItemConfigDirective} from './keyboard-navigation-item-config.directive';

@Directive({
  selector:
    '[tabindex][wKeyboardNavigationItem],a[wKeyboardNavigationItem],button[wKeyboardNavigationItem],input[wKeyboardNavigationItem],textarea[wKeyboardNavigationItem]',
  standalone: true,
  exportAs: 'wKeyboardNavigationItem',
})
export class KeyboardNavigationItemDirective implements OnInit, OnChanges, OnDestroy {
  @Input('wKeyboardNavigationItem') inputConfig?: KeyboardNavigationItemConfig | '';

  element: HTMLElement;
  elementTag: HTMLElement['localName'];
  config: KeyboardNavigationItemConfig | null = null;
  configChangeSubscription?: Subscription;
  origin: PositionObject;

  constructor(
    elem: ElementRef<HTMLElement>,
    @Optional() private service?: KeyboardNavigationService,
    @Optional() private configDirective?: KeyboardNavigationItemConfigDirective,
  ) {
    this.element = elem.nativeElement;
    this.elementTag = this.element.localName;
  }

  ngOnInit() {
    if (this.service) {
      this.configChangeSubscription = this.configDirective?.configChanged$.subscribe(() => this.updateConfig());
      this.service.registerItem(this);
      this.toggleEvents(true);
    }
  }

  ngOnChanges(changes: WSimpleChanges<KeyboardNavigationItemDirective>) {
    if (changes.inputConfig) {
      this.updateConfig();
    }
  }

  ngOnDestroy() {
    if (this.service) {
      this.configChangeSubscription?.unsubscribe();
      this.deactivate();
      this.toggleEvents(false);
      this.service.unregisterItem(this);
    }
  }

  get disabled(): boolean {
    return Boolean((this.element as any).disabled);
  }

  isVisible(): boolean {
    return isElementVisible(this.element, true);
  }

  getRect(): DOMRect {
    return this.element.getBoundingClientRect();
  }

  getPosition(): Position {
    return getPositionCoordsFromRect(this.getRect(), this.origin);
  }

  activate() {
    this.element.focus();
  }

  deactivate() {
    this.element.blur();
  }

  private updateConfig() {
    this.config = this.inputConfig || this.configDirective?.config || null;
    this.origin = parseTextPosition(this.config?.origin || 'center');
  }

  private toggleEvents(flag: boolean) {
    toggleEvent(this.element, 'focus', flag, this.handleFocus);
    toggleEvent(this.element, 'blur', flag, this.handleBlur);
  }

  private handleFocus = () => {
    this.service?.handleItemActivation(this);
  };

  private handleBlur = () => {
    this.service?.handleItemDeactivation(this);
  };
}
