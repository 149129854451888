<div class="w-page-main">
  <div class="w-main-header">
    <w-guest-header />
  </div>

  <w-page-banners class="w-page-main__sticky-area" wStickyArea />

  <div class="w-page-wrapper">
    <div class="w-page-wrapper__container">
      <div class="w-page">
        <router-outlet />
      </div>
    </div>
    <w-footer *ngIf="loaded$ | async" />
  </div>
</div>
