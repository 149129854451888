import {CssTheme, CssVariableRef} from '../theme-types';
import {Color} from '../colors';

export const LIGHT_THEME: CssTheme = {
  'backdrop-default': Color.$N040,
  'backdrop-landing': Color['$BR-N400'],
  'backdrop-dev': Color.$DEV200,
  'backdrop-test': Color.$TEST200,
  'backdrop-prod': Color.$PROD200,
  'backdrop-non-env': Color.$N200,
  'surface-default': Color.$N00,
  'surface-elevated': Color.$N00,
  'surface-field': Color.$N00,
  'surface-subtle': Color.$N030,
  'surface-alt': Color.$N050,
  'surface-inverse': Color.$N600,
  'text-primary': Color.$N500,
  'text-secondary': Color.$N300,
  'text-emphasis': Color.$N600,
  'text-inverse': Color.$N00,
  'link-default': Color.$B400,
  'link-emphasis': Color.$B500,
  'icon-default': new CssVariableRef('text-secondary'),
  'icon-emphasis': new CssVariableRef('text-emphasis'),
  'icon-inverse': new CssVariableRef('text-inverse'),
  'border-default': Color.$N075,
  'border-subtle': Color.$N050,
  'border-inverse': new CssVariableRef('text-inverse'),
  'brand-default': Color['$BR-T100'],
  'brand-emphasis': Color['$BR-T050'],
  'fg-onBrand': Color['$BR-N400'],
  'sidebar-main': Color.$N050,
  'sidebar-submenu': Color.$N030,
  'sidebar-active': Color.$N00,
  'sidebar-fg-primary': Color.$N500,
  'sidebar-fg-secondary': Color.$N400,
  'sidebar-fg-active': Color.$N600,
  'sidebar-border': Color.$N00,
  'nav-inverse': new CssVariableRef('sidebar-fg-primary'),

  'neutral-light': Color.$N020,
  'neutral-default': Color.$N300,
  'neutral-emphasis': Color.$N400,
  'neutral-bold': Color.$N400,
  'neutral-strong': Color['$N400-D'],
  'neutral-tint': Color.$N020,
  'neutral-subtle': Color.$N075,
  'neutral-muted': Color.$N100,
  'neutral-dark': Color.$N800,
  'neutral-fg': new CssVariableRef('neutral-default'),
  'neutral-fg-emphasis': new CssVariableRef('neutral-emphasis'),
  'fg-onNeutral': Color.$N00,
  'fg-onNeutral-light': Color.$N800,
  'fg-onNeutral-dark': Color.$N00,
  'neutral-border': new CssVariableRef('neutral-muted'),
  'neutral-border-emphasis': new CssVariableRef('neutral-emphasis'),

  'accent-default': Color.$T400,
  'accent-emphasis': Color.$T500,
  'accent-tint': Color.$T020,
  'accent-subtle': Color.$T100,
  'accent-muted': Color.$T200,
  'accent-fg': new CssVariableRef('accent-default'),
  'accent-fg-emphasis': new CssVariableRef('accent-emphasis'),
  'fg-onAccent': Color.$N00,
  'accent-border': new CssVariableRef('accent-muted'),
  'accent-border-emphasis': new CssVariableRef('accent-emphasis'),

  'edit-default': Color.$T400,
  'edit-emphasis': Color.$T500,
  'edit-bold': Color.$T500,
  'edit-tint': Color.$T020,
  'edit-subtle': Color.$T100,
  'edit-muted': Color.$T200,
  'edit-dark': Color.$T700,
  'edit-fg': new CssVariableRef('edit-default'),
  'edit-fg-emphasis': new CssVariableRef('edit-emphasis'),
  'edit-toggle-bg': Color.$N800,
  'fg-onEdit': Color.$N00,
  'fg-onEdit-dark': Color.$N00,
  'fg-onEdit-toggle': Color.$N00,
  'edit-border': new CssVariableRef('edit-muted'),
  'edit-border-emphasis': new CssVariableRef('edit-emphasis'),

  'test-default': Color.$N040,
  'test-emphasis': Color.$N100,
  'test-bold': Color.$N400,
  'test-tint': Color.$N020,
  'test-subtle': Color.$N050,
  'test-muted': Color.$N075,
  'test-dark': Color['$N400-D'],
  'test-fg': new CssVariableRef('test-default'),
  'test-fg-emphasis': new CssVariableRef('test-emphasis'),
  'test-toggle-bg': Color.$N800,
  'fg-onTest': Color.$N800,
  'fg-onTest-dark': Color.$N00,
  'fg-onTest-toggle': Color.$N00,
  'test-border': new CssVariableRef('test-muted'),
  'test-border-emphasis': new CssVariableRef('test-emphasis'),

  'test-alt-default': Color.$IN400,
  'test-alt-emphasis': Color.$IN500,
  'test-alt-tint': Color.$IN050,
  'test-alt-subtle': Color.$IN100,
  'test-alt-muted': Color.$IN200,
  'test-alt-bold': Color.$IN500,
  'test-alt-dark': Color.$IN700,
  'test-alt-fg': new CssVariableRef('test-alt-default'),
  'test-alt-fg-emphasis': new CssVariableRef('test-alt-emphasis'),
  'test-alt-toggle-bg': Color.$N800,
  'fg-onTest-alt': Color.$N00,
  'fg-onTest-alt-dark': Color.$N00,
  'fg-onTest-alt-toggle': Color.$N00,
  'test-alt-border': new CssVariableRef('test-alt-muted'),
  'test-alt-border-emphasis': new CssVariableRef('test-alt-emphasis'),

  'info-default': Color.$B400,
  'info-emphasis': Color.$B500,
  'info-tint': Color.$B050,
  'info-subtle': Color.$B100,
  'info-muted': Color.$B200,
  'info-fg': new CssVariableRef('info-default'),
  'info-fg-emphasis': new CssVariableRef('info-emphasis'),
  'fg-onInfo': Color.$N00,
  'info-border': new CssVariableRef('info-muted'),
  'info-border-emphasis': new CssVariableRef('info-emphasis'),

  'success-default': Color.$G500,
  'success-emphasis': Color.$G600,
  'success-tint': Color.$G050,
  'success-subtle': Color.$G100,
  'success-muted': Color.$G300,
  'success-fg': new CssVariableRef('success-default'),
  'success-fg-emphasis': new CssVariableRef('success-emphasis'),
  'fg-onSuccess': Color.$N00,
  'success-border': new CssVariableRef('success-muted'),
  'success-border-emphasis': new CssVariableRef('success-emphasis'),

  'warning-default': Color.$O400,
  'warning-emphasis': Color.$O500,
  'warning-tint': Color.$O050,
  'warning-subtle': Color.$O100,
  'warning-muted': Color.$O200,
  'warning-fg': new CssVariableRef('warning-default'),
  'warning-fg-emphasis': new CssVariableRef('warning-emphasis'),
  'fg-onWarning': Color.$N00,
  'warning-border': new CssVariableRef('warning-muted'),
  'warning-border-emphasis': new CssVariableRef('warning-emphasis'),

  'error-default': Color.$R400,
  'error-emphasis': Color.$R500,
  'error-tint': Color.$R020,
  'error-subtle': Color.$R100,
  'error-muted': Color.$R200,
  'error-fg': new CssVariableRef('error-default'),
  'error-fg-emphasis': new CssVariableRef('error-emphasis'),
  'fg-onError': Color.$N00,
  'error-border': new CssVariableRef('error-muted'),
  'error-border-emphasis': new CssVariableRef('error-emphasis'),

  'notice-default': Color.$SL400,
  'notice-emphasis': Color.$SL500,
  'notice-tint': Color.$SL020,
  'notice-subtle': Color.$SL050,
  'notice-muted': Color.$SL200,
  'notice-fg': new CssVariableRef('notice-default'),
  'notice-fg-emphasis': new CssVariableRef('notice-emphasis'),
  'fg-onNotice': Color.$N00,
  'notice-border': new CssVariableRef('notice-muted'),
  'notice-border-emphasis': new CssVariableRef('notice-emphasis'),

  'env-dev-default': Color.$DEV500,
  'env-dev-emphasis': Color.$DEV600,
  'env-dev-tint': Color.$DEV050,
  'env-dev-subtle': Color.$DEV100,
  'env-dev-muted': Color.$DEV200,
  'env-dev-fg': Color.$DEV500,
  'env-dev-fg-emphasis': Color.$DEV600,
  'fg-onEnv-dev': Color.$N00,
  'env-dev-border': Color.$DEV200,
  'env-dev-border-emphasis': Color.$DEV600,

  'env-test-default': Color.$TEST500,
  'env-test-emphasis': Color.$TEST600,
  'env-test-tint': Color.$TEST050,
  'env-test-subtle': Color.$TEST100,
  'env-test-muted': Color.$TEST300,
  'env-test-fg': Color.$TEST500,
  'env-test-fg-emphasis': Color.$TEST600,
  'fg-onEnv-test': Color.$N00,
  'env-test-border': Color.$TEST300,
  'env-test-border-emphasis': Color.$TEST600,

  'env-prod-default': Color.$PROD500,
  'env-prod-emphasis': Color.$PROD600,
  'env-prod-tint': Color.$PROD050,
  'env-prod-subtle': Color.$PROD100,
  'env-prod-muted': Color.$PROD200,
  'env-prod-fg': Color.$PROD500,
  'env-prod-fg-emphasis': Color.$PROD600,
  'fg-onEnv-prod': Color.$N00,
  'env-prod-border': Color.$PROD200,
  'env-prod-border-emphasis': Color.$PROD600,

  'rust-default': Color.$RS400,
  'rust-emphasis': Color.$RS500,
  'rust-tint': Color.$RS050,
  'rust-subtle': Color.$RS100,
  'rust-muted': Color.$RS200,
  'rust-fg': Color.$RS400,
  'rust-fg-emphasis': Color.$RS500,
  'fg-onRust': Color.$N00,
  'rust-border': Color.$RS200,
  'rust-border-emphasis': Color.$RS500,

  'olive-default': Color.$OL400,
  'olive-emphasis': Color.$OL500,
  'olive-tint': Color.$OL050,
  'olive-subtle': Color.$OL100,
  'olive-muted': Color.$OL200,
  'olive-fg': Color.$OL400,
  'olive-fg-emphasis': Color.$OL500,
  'fg-onOlive': Color.$N00,
  'olive-border': Color.$OL200,
  'olive-border-emphasis': Color.$OL500,

  'plum-default': Color.$PL400,
  'plum-emphasis': Color.$PL500,
  'plum-tint': Color.$PL050,
  'plum-subtle': Color.$PL100,
  'plum-muted': Color.$PL200,
  'plum-fg': Color.$PL400,
  'plum-fg-emphasis': Color.$PL500,
  'fg-onPlum': Color.$N00,
  'plum-border': Color.$PL200,
  'plum-border-emphasis': Color.$PL500,

  'violet-default': Color.$VI400,
  'violet-emphasis': Color.$VI500,
  'violet-tint': Color.$VI050,
  'violet-subtle': Color.$VI100,
  'violet-muted': Color.$VI200,
  'violet-fg': Color.$VI400,
  'violet-fg-emphasis': Color.$VI500,
  'fg-onViolet': Color.$N00,
  'violet-border': Color.$VI200,
  'violet-border-emphasis': Color.$VI500,

  'purple-default': Color.$P400,
  'purple-emphasis': Color.$P500,
  'purple-tint': Color.$P050,
  'purple-subtle': Color.$P100,
  'purple-muted': Color.$P200,
  'purple-fg': Color.$P400,
  'purple-fg-emphasis': Color.$P500,
  'fg-onPurple': Color.$N00,
  'purple-border': Color.$P200,
  'purple-border-emphasis': Color.$P500,

  'gold-default': Color.$GD400,
  'gold-emphasis': Color.$GD500,
  'gold-tint': Color.$GD020,
  'gold-subtle': Color.$GD100,
  'gold-muted': Color.$GD200,
  'gold-fg': Color.$GD400,
  'gold-fg-emphasis': Color.$GD500,
  'fg-onGold': Color.$N00,
  'gold-border': Color.$GD200,
  'gold-border-emphasis': Color.$GD500,

  'brown-default': Color.$BR400,
  'brown-emphasis': Color.$BR500,
  'brown-tint': Color.$BR050,
  'brown-subtle': Color.$BR100,
  'brown-muted': Color.$BR200,
  'brown-fg': Color.$BR400,
  'brown-fg-emphasis': Color.$BR500,
  'fg-onBrown': Color.$N00,
  'brown-border': Color.$BR200,
  'brown-border-emphasis': Color.$BR500,

  'forest-default': Color.$FR400,
  'forest-emphasis': Color.$FR500,
  'forest-tint': Color.$FR020,
  'forest-subtle': Color.$FR050,
  'forest-muted': Color.$FR200,
  'forest-fg': Color.$FR400,
  'forest-fg-emphasis': Color.$FR500,
  'fg-onForest': Color.$N00,
  'forest-border': Color.$FR200,
  'forest-border-emphasis': Color.$FR500,

  'teal-default': Color.$T400,
  'teal-emphasis': Color.$T500,
  'teal-tint': Color.$T020,
  'teal-subtle': Color.$T100,
  'teal-muted': Color.$T200,
  'teal-fg': Color.$T400,
  'teal-fg-emphasis': Color.$T500,
  'fg-onTeal': Color.$N00,
  'teal-border': Color.$T200,
  'teal-border-emphasis': Color.$T500,

  'blue-default': Color.$B400,
  'blue-emphasis': Color.$B500,
  'blue-tint': Color.$B050,
  'blue-subtle': Color.$B100,
  'blue-muted': Color.$B200,
  'blue-fg': Color.$B400,
  'blue-fg-emphasis': Color.$B500,
  'fg-onBlue': Color.$N00,
  'blue-border': Color.$B200,
  'blue-border-emphasis': Color.$B500,

  'indigo-default': Color.$IN400,
  'indigo-emphasis': Color.$IN500,
  'indigo-tint': Color.$IN050,
  'indigo-subtle': Color.$IN100,
  'indigo-muted': Color.$IN200,
  'indigo-fg': Color.$IN400,
  'indigo-fg-emphasis': Color.$IN500,
  'fg-onIndigo': Color.$N00,
  'indigo-border': Color.$IN200,
  'indigo-border-emphasis': Color.$IN500,

  'green-default': Color.$G500,
  'green-emphasis': Color.$G600,
  'green-tint': Color.$G050,
  'green-subtle': Color.$G100,
  'green-muted': Color.$G300,
  'green-fg': Color.$G500,
  'green-fg-emphasis': Color.$G600,
  'fg-onGreen': Color.$N00,
  'green-border': Color.$G300,
  'green-border-emphasis': Color.$G600,

  'orange-default': Color.$O400,
  'orange-emphasis': Color.$O500,
  'orange-tint': Color.$O050,
  'orange-subtle': Color.$O100,
  'orange-muted': Color.$O200,
  'orange-fg': Color.$O400,
  'orange-fg-emphasis': Color.$O500,
  'fg-onOrange': Color.$N00,
  'orange-border': Color.$O200,
  'orange-border-emphasis': Color.$O500,

  'red-default': Color.$R400,
  'red-emphasis': Color.$R500,
  'red-tint': Color.$R020,
  'red-subtle': Color.$R100,
  'red-muted': Color.$R200,
  'red-fg': Color.$R400,
  'red-fg-emphasis': Color.$R500,
  'fg-onRed': Color.$N00,
  'red-border': Color.$R200,
  'red-border-emphasis': Color.$R500,

  'slate-default': Color.$SL400,
  'slate-emphasis': Color.$SL500,
  'slate-tint': Color.$SL020,
  'slate-subtle': Color.$SL050,
  'slate-muted': Color.$SL200,
  'slate-fg': Color.$SL400,
  'slate-fg-emphasis': Color.$SL500,
  'fg-onSlate': Color.$N00,
  'slate-border': Color.$SL200,
  'slate-border-emphasis': Color.$SL500,

  'mktg-neutral-default': Color['$BR-N400'],
  'mktg-neutral-emphasis': Color['$BR-N200'],
  'fg-onMktg-neutral': Color['$BR-N00'],

  'utility-bg-teal': Color.$T100,
  'utility-bg-gold': Color.$GD100,
  'utility-bg-blue': Color.$B100,
  'utility-bg-orange': Color.$O100,
  'utility-bg-red': Color.$R100,
  'utility-bg-slate': Color.$SL100,
  'utility-bg-neutral': Color.$N050,
  'utility-fg-teal': Color.$T500,
  'utility-fg-gold': Color.$GD500,
  'utility-fg-blue': Color.$B500,
  'utility-fg-orange': Color.$O500,
  'utility-fg-red': Color.$R500,
  'utility-fg-slate': Color.$SL500,
  'utility-fg-neutral': Color.$N500,
  'utility-border': Color.$N200,

  'ai-default': Color.$DEV500,
  'ai-emphasis': Color.$DEV600,
  'ai-tint': Color.$DEV020,
  'ai-subtle': Color.$DEV050,
  'ai-muted': Color.$DEV200,
  'ai-fg': Color.$DEV500,
  'ai-fg-emphasis': Color.$DEV600,
  'fg-onAi': Color.$N00,
  'ai-border': Color.$DEV200,
  'ai-border-emphasis': Color.$DEV600,

  'ai-alt-default': Color.$AQ400,
  'ai-alt-emphasis': Color.$AQ500,
  'ai-alt-tint': Color.$AQ020,
  'ai-alt-subtle': Color.$AQ050,
  'ai-alt-muted': Color.$AQ200,
  'ai-alt-fg': Color.$AQ400,
  'ai-alt-fg-emphasis': Color.$AQ500,
  'fg-onAi-alt': Color.$N800,
  'ai-alt-border': Color.$AQ200,
  'ai-alt-border-emphasis': Color.$AQ500,

  'copilot-primary': Color.$DEV500,
  'copilot-secondary': Color.$DEV600,
  'copilot-alt': Color.$AQ400,

  'chart-teal-muted': Color.$T200,
  'chart-teal-subtle': Color.$T100,
  'chart-teal-tint': Color.$T020,
  'chart-teal-fg': Color.$T400,
  'chart-teal-border': Color.$T200,
  'chart-blue-muted': Color.$B200,
  'chart-blue-subtle': Color.$B100,
  'chart-blue-tint': Color.$B050,
  'chart-blue-fg': Color.$B400,
  'chart-blue-border': Color.$B200,
  'chart-violet-muted': Color.$VI200,
  'chart-violet-subtle': Color.$VI100,
  'chart-violet-tint': Color.$VI050,
  'chart-violet-fg': Color.$VI400,
  'chart-violet-border': Color.$VI200,
  'chart-red-muted': Color.$R200,
  'chart-red-subtle': Color.$R100,
  'chart-red-tint': Color.$R020,
  'chart-red-fg': Color.$R400,
  'chart-red-border': Color.$R200,
  'chart-green-muted': Color.$G300,
  'chart-green-subtle': Color.$G100,
  'chart-green-tint': Color.$G050,
  'chart-green-fg': Color.$G500,
  'chart-green-border': Color.$G300,
  'chart-brown-muted': Color.$BR200,
  'chart-brown-subtle': Color.$BR100,
  'chart-brown-tint': Color.$BR050,
  'chart-brown-fg': Color.$BR400,
  'chart-brown-border': Color.$BR200,
  'chart-orange-muted': Color.$O200,
  'chart-orange-subtle': Color.$O100,
  'chart-orange-tint': Color.$O050,
  'chart-orange-fg': Color.$O400,
  'chart-orange-border': Color.$O200,
  'chart-gold-muted': Color.$GD200,
  'chart-gold-subtle': Color.$GD100,
  'chart-gold-tint': Color.$GD020,
  'chart-gold-fg': Color.$GD400,
  'chart-gold-border': Color.$GD200,
  'chart-indigo-muted': Color.$IN200,
  'chart-indigo-subtle': Color.$IN100,
  'chart-indigo-tint': Color.$IN050,
  'chart-indigo-fg': Color.$IN400,
  'chart-indigo-border': Color.$IN200,
  'chart-plum-muted': Color.$PL200,
  'chart-plum-subtle': Color.$PL100,
  'chart-plum-tint': Color.$PL050,
  'chart-plum-fg': Color.$PL400,
  'chart-plum-border': Color.$PL100,
  'chart-slate-muted': Color.$SL200,
  'chart-slate-subtle': Color.$SL050,
  'chart-slate-tint': Color.$SL020,
  'chart-slate-fg': Color.$SL400,
  'chart-slate-border': Color.$SL200,
  'chart-rose-muted': Color.$PROD200,
  'chart-rose-subtle': Color.$PROD100,
  'chart-rose-tint': Color.$PROD050,
  'chart-rose-fg': Color.$PROD500,
  'chart-rose-border': Color.$PROD200,
  'chart-yellow-muted': Color.$TEST300,
  'chart-yellow-subtle': Color.$TEST100,
  'chart-yellow-tint': Color.$TEST050,
  'chart-yellow-fg': Color.$TEST500,
  'chart-yellow-border': Color.$TEST300,
  'chart-cobalt-muted': Color.$DEV200,
  'chart-cobalt-subtle': Color.$DEV100,
  'chart-cobalt-tint': Color.$DEV050,
  'chart-cobalt-fg': Color.$DEV500,
  'chart-cobalt-border': Color.$DEV200,
};
