import {ChangeDetectionStrategy, Component, Input, ViewEncapsulation} from '@angular/core';
import orchestrateIcon from '@icons/layout/orchestrate.svg';
import agenticIcon from '@icons/layout/agentic.svg';

import {SvgIconComponent} from '@shared/components/svg-icon/svg-icon.component';

import {Product, ProductId} from '../app-context-switcher/app-context-switcher.service';
import {Workspace} from '../../services/auth-user';
import {AccountEnvironment} from '../../services/account-environments.types';
import {ACCOUNT_ENVIRONMENT_ICONS} from '../../services/account-environments.service';
import {UserAvatarModule} from '../../modules/user-avatar/user-avatar.module';

type ContextType = 'workspace' | 'product' | 'environment';
type ContextValue<TContextType extends ContextType> = TContextType extends 'workspace'
  ? Workspace
  : TContextType extends 'product'
    ? Product
    : TContextType extends 'environment'
      ? AccountEnvironment
      : void;

@Component({
  selector: 'w-app-context-switcher-icon',
  templateUrl: './app-context-switcher-icon.component.html',
  styleUrl: './app-context-switcher-icon.component.scss',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [SvgIconComponent, UserAvatarModule],
})
export class AppContextSwitcherIconComponent<TContextType extends ContextType> {
  @Input({required: true}) type: TContextType;
  @Input({required: true}) value: ContextValue<TContextType>;

  readonly PRODUCT_ICONS: Record<ProductId, SvgIcon['id']> = {
    orchestrate: orchestrateIcon.id,
    agentic: agenticIcon.id,
  };

  readonly ENV_ICONS = ACCOUNT_ENVIRONMENT_ICONS;

  isWorkspace(): this is AppContextSwitcherIconComponent<'workspace'> {
    return this.type === 'workspace';
  }

  isProduct(): this is AppContextSwitcherIconComponent<'product'> {
    return this.type === 'product';
  }

  isEnvironment(): this is AppContextSwitcherIconComponent<'environment'> {
    return this.type === 'environment';
  }
}
