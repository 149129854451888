import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({providedIn: 'root'})
export class AppMobileSidebarService {
  isSidebarOpened$: Observable<boolean>;

  private _isSidebarOpened = new BehaviorSubject<boolean>(false);

  constructor() {
    this.isSidebarOpened$ = this._isSidebarOpened.asObservable();
  }

  openSidebar() {
    this._isSidebarOpened.next(true);
  }

  closeSidebar() {
    this._isSidebarOpened.next(false);
  }
}
