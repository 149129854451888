import {ErrorHandler, Injectable, OnDestroy} from '@angular/core';
import {Observable, Subject} from 'rxjs';

import {DialogInstance} from '@shared/modules/dialog/dialog.types';
import {AbstractUserSessionService} from '@shared/services/abstract-user-session.service';

import {TabsCommunicationService} from './tabs-communication/tabs-communication.service';
import {BroadcastChannelAdapter} from './tabs-communication/broadcast-channel-adapters/broadcast-channel-adapter';
import {RootDialogService} from './root-dialog.service';
import {AuthUser} from './auth-user';

export type SessionChangeReason = 'user_logout' | 'user_change';

type UserIdMessage = number;

const CHANNEL_ID = 'userSession';
const USER_LOGOUT_ID = -1;
const LOGOUT_PATH = '/users/sign_out';

@Injectable({providedIn: 'root'})
export class UserSessionService implements AbstractUserSessionService, OnDestroy {
  login$: Observable<void>;
  logout$: Observable<void>;
  private currentDialog: DialogInstance | null = null;
  private loginSubject = new Subject<void>();
  private logoutSubject = new Subject<void>();

  private readonly channelAdapter: BroadcastChannelAdapter<UserIdMessage>;

  constructor(
    private authUser: AuthUser,
    private dialogService: RootDialogService,
    private tabsCommunicationService: TabsCommunicationService,
    private errorHandler: ErrorHandler,
  ) {
    this.channelAdapter = this.tabsCommunicationService.createChannel(CHANNEL_ID);
    this.logout$ = this.logoutSubject.asObservable();
    this.login$ = this.loginSubject.asObservable();
  }

  ngOnDestroy() {
    this.channelAdapter.close();
  }

  get hasExpiredSessionPopup(): boolean {
    return Boolean(this.currentDialog?.opened);
  }

  get logoutUrl(): string {
    return `${this.authUser.agentic ? this.authUser.agentic_url : this.authUser.orchestrate_url}${LOGOUT_PATH}`;
  }

  init() {
    if (!this.authUser.authenticated) {
      return;
    }

    this.loginSubject.next();
    this.channelAdapter.setMessageHandler(this.messageHandler);
    this.postMessage(this.authUser.id);
  }

  logout() {
    this.postMessage(USER_LOGOUT_ID);
    this.logoutSubject.next();
    window.location.href = this.logoutUrl;
  }

  private postMessage(message: UserIdMessage) {
    try {
      this.channelAdapter.postMessage(message);
    } catch (error) {
      this.errorHandler.handleError(error);
    }
  }

  private async showExpiredSessionPopup(reason: SessionChangeReason) {
    this.currentDialog?.close();

    const expiredSessionPopupComponent = await import(
      '../components/expired-session-popup/expired-session-popup.component'
    ).then(m => m.ExpiredSessionPopupComponent);

    this.currentDialog = this.dialogService.open(expiredSessionPopupComponent, {
      class: 'w-dialog_blurred',
      cancellable: false,
      contentInputs: {reason},
    });
  }

  private messageHandler = (newUserId: UserIdMessage): void => {
    if (newUserId === this.authUser.id) {
      this.currentDialog?.close();

      return;
    }

    const reason: SessionChangeReason = newUserId === USER_LOGOUT_ID ? 'user_logout' : 'user_change';

    this.showExpiredSessionPopup(reason);
  };
}
