<button
  class="app-mobile-sidebar-button app-mobile-sidebar-button_{{ type }}"
  type="button"
  [disabled]="disabled"
  [attr.aria-label]="ariaLabel"
  wKeyboardNavigationItem
>
  @if (type === 'back' && !disabled) {
    <w-svg-icon class="app-mobile-sidebar-button__icon" iconId="chevron-left" [size]="12" />
  }
  <span class="app-mobile-sidebar-button__text"><ng-content /></span>
  @if (type === 'forward' && !disabled) {
    <w-svg-icon class="app-mobile-sidebar-button__icon" iconId="chevron-right" [size]="12" />
  }
</button>
