import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class AppLogoutStateService {
  canLogout$: Observable<boolean>;
  logoutDisabledReason$: Observable<string | null>;

  private logoutDisabledReason = new BehaviorSubject<string | null>(null);

  constructor() {
    this.canLogout$ = this.logoutDisabledReason.pipe(map(reason => !reason));
    this.logoutDisabledReason$ = this.logoutDisabledReason.asObservable();
  }

  disableLogout(reason: string) {
    this.logoutDisabledReason.next(reason);
  }

  enableLogout() {
    this.logoutDisabledReason.next(null);
  }
}
