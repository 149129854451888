import {Component} from '@angular/core';
import {Observable} from 'rxjs';

import {PageSpinnerService} from '@shared/services/page-spinner.service';

@Component({
  selector: 'w-guest-layout',
  templateUrl: './guest-layout.component.html',
})
export class GuestLayoutComponent {
  loaded$: Observable<boolean>;

  constructor(private pageSpinner: PageSpinnerService) {
    this.loaded$ = this.pageSpinner.isHidden$;
  }
}
