import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import {Observable} from 'rxjs';
import themeLightIcon from '@icons/layout/theme-light.svg';
import themeLightActiveIcon from '@icons/layout/theme-light-active.svg';
import themeDarkIcon from '@icons/layout/theme-dark.svg';
import themeDarkActiveIcon from '@icons/layout/theme-dark-active.svg';
import themeSystemIcon from '@icons/layout/theme-system.svg';
import themeSystemActiveIcon from '@icons/layout/theme-system-active.svg';

import {subscriptions} from '@shared/services/subscriptions';

import {UserAvatarModule} from '../../modules/user-avatar/user-avatar.module';
import {AuthUser} from '../../services/auth-user';
import {ThemePreference, ThemeService} from '../../theming/theme.service';
import {SharedModule} from '../../shared.module';
import {KeyboardNavigationModule} from '../../modules/keyboard-navigation/keyboard-navigation.module';

import {AppLogoutStateService} from './app-logout-state.service';

interface ThemeOption {
  id: ThemePreference;
  name: string;
  iconId: SvgIcon['id'];
  activeIconId: SvgIcon['id'];
}

const PROFILE_PATH = '/users/current/edit';

@Component({
  selector: 'w-app-user-menu',
  templateUrl: './app-user-menu.component.html',
  styleUrl: './app-user-menu.component.scss',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [SharedModule, UserAvatarModule, KeyboardNavigationModule],
})
export class AppUserMenuComponent implements OnInit {
  @Output() linkClick = new EventEmitter<MouseEvent>();

  logoutDisabledReason: string | null = null;
  themePreference$: Observable<ThemePreference>;

  themes: ThemeOption[] = [
    {id: 'light', name: 'Light', iconId: themeLightIcon.id, activeIconId: themeLightActiveIcon.id},
    {id: 'dark', name: 'Dark', iconId: themeDarkIcon.id, activeIconId: themeDarkActiveIcon.id},
    {id: 'match-browser', name: 'System', iconId: themeSystemIcon.id, activeIconId: themeSystemActiveIcon.id},
  ];

  private subs = subscriptions();

  constructor(
    private authUser: AuthUser,
    private themeService: ThemeService,
    private appLogoutState: AppLogoutStateService,
    private cd: ChangeDetectorRef,
  ) {
    this.themePreference$ = this.themeService.themePreference$;
  }

  ngOnInit() {
    this.subs.add(
      this.appLogoutState.logoutDisabledReason$.subscribe(reason => {
        this.logoutDisabledReason = reason;
        this.cd.markForCheck();
      }),
    );
  }

  get userName(): string {
    return this.authUser.logged_name;
  }

  get userAvatar(): string {
    return this.authUser.profile_image;
  }

  get isOemUser(): boolean {
    return this.authUser.oem_user;
  }

  get isAgentic(): boolean {
    return this.authUser.agentic;
  }

  get profileRouterLink(): string | null {
    return PROFILE_PATH;
  }

  get profileHref(): string {
    return `${this.authUser.orchestrate_url}${PROFILE_PATH}`;
  }

  selectTheme(preference: ThemePreference) {
    this.themeService.setTheme(preference);
  }
}
