import {NgStyle} from '@angular/common';
import {ChangeDetectionStrategy, Component, Input, OnChanges, ViewEncapsulation} from '@angular/core';

import {WSimpleChanges} from '@shared/types/angular';

import {AVATAR_PALETTE, AvatarPaletteColor, getColorByName} from '../../../utils/avatar-palette';
import {getAvatarBorderSize} from '../avatar-helpers';

@Component({
  selector: 'w-user-initials-avatar',
  templateUrl: './user-initials-avatar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./user-initials-avatar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UserInitialsAvatarComponent implements OnChanges {
  @Input({required: true}) name: string;
  @Input({required: true}) size: number;
  @Input() borderRadius?: number;
  @Input() border = false;

  backgroundColor: AvatarPaletteColor;
  displayedName = '';

  constructor() {
    this.backgroundColor = AVATAR_PALETTE[0];
  }

  ngOnChanges(changes: WSimpleChanges<UserInitialsAvatarComponent>) {
    if (changes.name) {
      this.backgroundColor = getColorByName(this.name);
      this.displayedName = this.name.slice(0, 1).toUpperCase();
    }
  }

  get avatarStyles(): NgStyle['ngStyle'] {
    return {
      'background-color': this.backgroundColor,
      'font-size.px': this.size * 0.6,
      'height.px': this.size,
      'width.px': this.size,
      'line-height.px': this.size,
      'border-width.px': this.border ? getAvatarBorderSize(this.size) : null,
      'border-radius.px': this.borderRadius,
    };
  }
}
