import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {A11yModule} from '@angular/cdk/a11y';

import {SvgIconComponent} from '@shared/components/svg-icon/svg-icon.component';
import {SpinnerComponent} from '@shared/components/spinner/spinner.component';
import {AbstractScreenSizeService} from '@shared/services/abstract-screen-size.service';
import {TimesPipe} from '@shared/pipes/times.pipe';
import {TrapScrollDirective} from '@shared/directives/trap-scroll.directive';
import {HoverDirective} from '@shared/directives/hover.directive';
import {TextHighlightPipe} from '@shared/pipes/text-highlight.pipe';
import {FocusWhenDirective} from '@shared/directives/focus-when.directive';
import {DatePipe} from '@shared/pipes/date.pipe';
import {TemplateOrTextComponent} from '@shared/components/template-or-text/template-or-text.component';
import {SafeHtmlPipe} from '@shared/pipes/safe-html.pipe';
import {ButtonComponent} from '@shared/components/button/button.component';
import {LayoutService} from '@shared/services/layout.service';
import {TooltipComponent} from '@shared/modules/tooltip/tooltip.component';
import {TooltipDirective} from '@shared/modules/tooltip/tooltip.directive';
import {ExternalLinkComponent} from '@shared/components/external-link/external-link.component';
import {FORBID_EXTERNAL_SAME_ORIGIN_LINK} from '@shared/components/external-link/external-link-tokens';
import {SwitchControlComponent} from '@shared/components/switch-control/switch-control.component';
import {SortIconComponent} from '@shared/components/sort-icon/sort-icon.component';
import {OverlayModule} from '@shared/modules/overlay/overlay.module';
import {RouterModule} from '@shared/modules/router/router.module';
import {IconButtonComponent} from '@shared/components/icon-button/icon-button.component';
import {LetDirective} from '@shared/directives/let.directive';
import {LowerFirstPipe} from '@shared/pipes/lower-first.pipe';

import {DateUtcPipe} from './pipes/date-utc.pipe';
import {SafeUrlPipe} from './pipes/safe-url.pipe';
import {SortPipe} from './pipes/sort.pipe';
import {FilterPipe} from './pipes/filter.pipe';
import {RejectPipe} from './pipes/reject.pipe';
import {AbbrNumberPipe} from './pipes/abbr-number.pipe';
import {Src2xPipe} from './pipes/src2x.pipe';
import {ParseJsonPipe} from './pipes/parse-json.pipe';
import {TransformPipe} from './pipes/transform.pipe';
import {FlashComponent} from './components/flash/flash.component';
import {FormGroupComponent} from './components/form-group/form-group.component';
import {ResizableDirective} from './directives/resizable.directive';
import {MaskPipe} from './pipes/mask.pipe';
import {UrlEncodePipe} from './pipes/url-encode.pipe';
import {LazyShowDirective} from './directives/lazy-show.directive';
import {WebsiteUrlPipe} from './pipes/website-url.pipe';
import {LogoComponent} from './components/logo/logo.component';
import {MixpanelDirective} from './directives/mixpanel.directive';
import {SafeResourceUrlPipe} from './pipes/safe-resource-url.pipe';
import {RecreateViewDirective} from './directives/recreate-view.directive';
import {AfterRenderDirective} from './directives/after-render.directive';
import {OnDestroyDirective} from './directives/on-destroy.directive';
import {PagingPipe} from './pipes/paging.pipe';
import {HasPermissionDirective} from './directives/has-permission.directive';
import {ReversePipe} from './pipes/reverse.pipe';
import {LogoutButtonDirective} from './directives/logout-button.directive';
import {RouterUrlToHrefPipe} from './pipes/router-url-to-href.pipe';
import {ThemeColorPipe} from './pipes/theme-color.pipe';
import {HasPermissionPipe} from './pipes/has-permission.pipe';
import {NormalizeSpacesPipe} from './pipes/normalize-spaces.pipe';
import {ScrollIntoViewDirective} from './directives/scroll-into-view.directive';
import {TextPreviewAsideDirective} from './components/text-preview/text-preview-aside.directive';
import {OemService} from './modules/oem/oem.service';
import {TextPreviewComponent} from './components/text-preview/text-preview.component';
import {UpperFirstPipe} from './pipes/upper-first.pipe';
import {BubbleModule} from './components/bubble/bubble.module';
import {FileNameFromUrlPipe} from './pipes/file-name-from-url.pipe';
import {FULLSCREEN_DISABLED} from './modules/fullscreen/fullscreen-disabled.token';
import {DateTimeLabelComponent} from './components/date-time-label/date-time-label.component';

const pipes = [
  DateUtcPipe,
  SafeUrlPipe,
  SafeResourceUrlPipe,
  SortPipe,
  FilterPipe,
  RejectPipe,
  AbbrNumberPipe,
  Src2xPipe,
  LowerFirstPipe,
  UpperFirstPipe,
  ParseJsonPipe,
  TransformPipe,
  MaskPipe,
  UrlEncodePipe,
  WebsiteUrlPipe,
  PagingPipe,
  ReversePipe,
  RouterUrlToHrefPipe,
  ThemeColorPipe,
  HasPermissionPipe,
  NormalizeSpacesPipe,
  FileNameFromUrlPipe,
];

const components = [FlashComponent, FormGroupComponent, LogoComponent, TextPreviewComponent, TextPreviewAsideDirective];

const standaloneComponents = [
  SvgIconComponent,
  SpinnerComponent,
  TemplateOrTextComponent,
  ButtonComponent,
  TooltipComponent,
  DateTimeLabelComponent,
  ExternalLinkComponent,
  SwitchControlComponent,
  SortIconComponent,
  IconButtonComponent,
];
const standaloneDirectives = [
  TrapScrollDirective,
  HoverDirective,
  FocusWhenDirective,
  TooltipDirective,
  LetDirective,
  MixpanelDirective,
];
const standalonePipes = [TimesPipe, DatePipe, TextHighlightPipe, SafeHtmlPipe];

const directives = [
  ResizableDirective,
  LazyShowDirective,
  RecreateViewDirective,
  AfterRenderDirective,
  OnDestroyDirective,
  HasPermissionDirective,
  LogoutButtonDirective,
  ScrollIntoViewDirective,
];

@NgModule({
  imports: [
    CommonModule,
    A11yModule,
    RouterModule,
    OverlayModule,
    BubbleModule,
    ...standaloneComponents,
    ...standaloneDirectives,
    ...standalonePipes,
  ],
  exports: [
    CommonModule,
    A11yModule,
    OverlayModule,
    RouterModule,
    BubbleModule,
    ...pipes,
    ...directives,
    ...components,
    ...standaloneComponents,
    ...standaloneDirectives,
    ...standalonePipes,
  ],
  declarations: [...pipes, ...directives, ...components],
  providers: [
    ...pipes,
    ...standalonePipes,
    {
      provide: AbstractScreenSizeService,
      useClass: LayoutService,
    },
    {
      provide: FORBID_EXTERNAL_SAME_ORIGIN_LINK,
      useFactory: (oemService: OemService) => oemService.forbidExternalWorkatoLinks,
      deps: [OemService],
    },
    {
      provide: FULLSCREEN_DISABLED,
      useFactory: (oemService: OemService) => oemService.isEmbeddedWidget,
      deps: [OemService],
    },
  ],
})
export class SharedModule {}
