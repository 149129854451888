import {Injectable, NgZone, OnDestroy} from '@angular/core';

import {GlobalCaptureEventListeners} from '../../services/global-capture-event-listeners.service';
import {DirectionVector, Position} from '../../types';

import {KeyboardNavigationItemDirective} from './keyboard-navigation-item.directive';
import {KeyboardNavigationConfig, KeyboardNavigationDirection} from './keyboard-navigation.types';

type ItemCutoutFunction = (itemCenter: Position, activeItemRect: DOMRect) => boolean;

const KEY_TO_DIRECTION_MAP = new Map<KeyboardEvent['key'], KeyboardNavigationDirection>([
  ['ArrowUp', KeyboardNavigationDirection.UP],
  ['ArrowDown', KeyboardNavigationDirection.DOWN],
  ['ArrowLeft', KeyboardNavigationDirection.LEFT],
  ['ArrowRight', KeyboardNavigationDirection.RIGHT],
]);

const DIRECTION_TO_VECTOR_MAP = new Map<KeyboardNavigationDirection, DirectionVector>([
  [KeyboardNavigationDirection.UP, {x: 0, y: -1}],
  [KeyboardNavigationDirection.DOWN, {x: 0, y: 1}],
  [KeyboardNavigationDirection.LEFT, {x: -1, y: 0}],
  [KeyboardNavigationDirection.RIGHT, {x: 1, y: 0}],
]);

const DIRECTION_TO_ITEM_CUTOUT_FN_MAP = new Map<KeyboardNavigationDirection, ItemCutoutFunction>([
  /* When navigating up we ignore all items located below the top of currently active item */
  [KeyboardNavigationDirection.UP, (itemPosition, activeItemRect) => itemPosition.top >= activeItemRect.top],
  /* When navigating down we ignore all items located above the bottom of currently active item */
  [KeyboardNavigationDirection.DOWN, (itemPosition, activeItemRect) => itemPosition.top <= activeItemRect.bottom],
  /* When navigating left we ignore all items located to the right of the left edge of currently active item */
  [KeyboardNavigationDirection.LEFT, (itemPosition, activeItemRect) => itemPosition.left >= activeItemRect.left],
  /* When navigating right we ignore all items located to the left of the right edge of currently active item */
  [KeyboardNavigationDirection.RIGHT, (itemPosition, activeItemRect) => itemPosition.left <= activeItemRect.right],
]);

const OPPOSITE_DIRECTION_MAP = new Map<KeyboardNavigationDirection, KeyboardNavigationDirection>([
  [KeyboardNavigationDirection.LEFT, KeyboardNavigationDirection.RIGHT],
  [KeyboardNavigationDirection.RIGHT, KeyboardNavigationDirection.LEFT],
  [KeyboardNavigationDirection.UP, KeyboardNavigationDirection.DOWN],
  [KeyboardNavigationDirection.DOWN, KeyboardNavigationDirection.UP],
]);

@Injectable()
export class KeyboardNavigationService implements OnDestroy {
  static activeInstance: KeyboardNavigationService | null = null;

  items = new Set<KeyboardNavigationItemDirective>();
  activeItem: KeyboardNavigationItemDirective | null = null;
  config?: KeyboardNavigationConfig;

  private deactivationTimeoutId?: number;

  constructor(
    private ngZone: NgZone,
    private globalEvents: GlobalCaptureEventListeners,
  ) {}

  ngOnDestroy() {
    this.deactivate();
  }

  get active(): boolean {
    return KeyboardNavigationService.activeInstance === this;
  }

  configure(config: KeyboardNavigationConfig) {
    this.config = config;

    if (config.autofocus) {
      this.autofocus();
    }
  }

  autofocus() {
    if (!this.active) {
      this.findInitialItem(this.getActivatableItems())?.activate();
    }
  }

  activate() {
    if (this.active) {
      return;
    }

    KeyboardNavigationService.activeInstance?.deactivate();
    KeyboardNavigationService.activeInstance = this;
    this.ngZone.runOutsideAngular(() => {
      this.globalEvents.add('keydown', this.handlePageKeydown);
    });
  }

  deactivate() {
    if (!this.active) {
      return;
    }

    KeyboardNavigationService.activeInstance = null;
    this.globalEvents.remove('keydown', this.handlePageKeydown);
  }

  navigate(direction: KeyboardNavigationDirection) {
    const items = this.getActivatableItems();

    if (!items.length) {
      return;
    }

    let newActiveItem: KeyboardNavigationItemDirective | undefined;

    if (this.activeItem && this.activeItem.isVisible()) {
      // Finding the closest item to currently active one
      newActiveItem = this.findNextActiveItem(this.activeItem, items, direction);

      if (!newActiveItem && this.config?.cyclic) {
        // Finding the farthest item to currently active one located in the opposite direction
        newActiveItem = this.findNextActiveItem(this.activeItem, items, OPPOSITE_DIRECTION_MAP.get(direction)!, {
          preferFarthest: true,
        });
      }

      if (newActiveItem) {
        this.activeItem = newActiveItem;
      }
    } else {
      this.activeItem = this.findInitialItem(items)!;
    }

    this.activeItem.activate();
  }

  registerItem(item: KeyboardNavigationItemDirective) {
    this.items.add(item);
  }

  unregisterItem(item: KeyboardNavigationItemDirective) {
    this.items.delete(item);
  }

  handleItemActivation(item: KeyboardNavigationItemDirective) {
    if (this.items.has(item)) {
      this.activate();
      this.activeItem = item;
      clearTimeout(this.deactivationTimeoutId);
    }
  }

  handleItemDeactivation(item: KeyboardNavigationItemDirective) {
    if (item === this.activeItem) {
      this.activeItem = null;
      clearTimeout(this.deactivationTimeoutId);
      /*
       * This timer is an optimization measure in cases when user navigates from one item to another via `Tab`:
       * in this case `blur` on the previous item is immediately followed by `focus` on the next one, but events are
       * fired asynchronously so this timer prevents unnecessary reactivation of the service.
       */
      this.deactivationTimeoutId = setTimeout(() => this.deactivate());
    }
  }

  /**
   * Finds item with minimal `top` and `left` coords
   */
  private findInitialItem(items: KeyboardNavigationItemDirective[]): KeyboardNavigationItemDirective | undefined {
    let initialItem: KeyboardNavigationItemDirective | undefined;
    let initialItemRect: DOMRect | undefined;

    for (let i = 0, len = items.length; i < len; i++) {
      const item = items[i];
      const itemRect = item.getRect();

      if (
        !initialItem ||
        itemRect.top < initialItemRect!.top ||
        (itemRect.top === initialItemRect!.top && itemRect.left < initialItemRect!.left)
      ) {
        initialItem = item;
        initialItemRect = itemRect;
      }
    }

    return initialItem;
  }

  private findNextActiveItem(
    activeItem: KeyboardNavigationItemDirective,
    items: KeyboardNavigationItemDirective[],
    direction: KeyboardNavigationDirection,
    opts?: {preferFarthest?: boolean},
  ): KeyboardNavigationItemDirective | undefined {
    const activeItemPosition = activeItem.getPosition();
    const activeItemRect = activeItem.getRect();
    const itemCutoutFn = DIRECTION_TO_ITEM_CUTOUT_FN_MAP.get(direction)!;
    let distanceToFarthestItem = 0;
    let distanceToClosestItem = Number.POSITIVE_INFINITY;
    const itemsInfo: Array<{
      item: KeyboardNavigationItemDirective;
      directionVector: DirectionVector;
      distance: number;
    }> = [];

    for (const item of items) {
      if (item === activeItem) {
        continue;
      }

      const itemPosition = item.getPosition();

      if (itemCutoutFn(itemPosition, activeItemRect)) {
        continue;
      }

      const itemDirectionVector: DirectionVector = {
        x: itemPosition.left - activeItemPosition.left,
        y: itemPosition.top - activeItemPosition.top,
      };
      const distanceToItem = this.getDirectionVectorLength(itemDirectionVector);

      itemsInfo.push({
        item,
        directionVector: itemDirectionVector,
        distance: distanceToItem,
      });

      distanceToFarthestItem = Math.max(distanceToFarthestItem, distanceToItem);
      distanceToClosestItem = Math.min(distanceToClosestItem, distanceToItem);
    }

    let newActiveItem: KeyboardNavigationItemDirective | undefined;
    let newActiveItemMatchRatio = 0;

    for (const {item, directionVector, distance} of itemsInfo) {
      /*
       * Item match ratio is a value from 0 to 1 where 1 corresponds to the best matching item.
       * When `preferFarthest` option is set we prefer proper alignment (weight 0.7) over the distance
       * to the item (weight 0.3) and vise-versa otherwise.
       * These weights are chosen experimentally to best match an expected navigation behavior.
       */
      const angleRatioWeight = opts?.preferFarthest ? 0.7 : 0.3;
      const distanceRatioWeight = 1 - angleRatioWeight;
      const itemMatchRatio =
        angleRatioWeight * this.getAlignmentRatio(directionVector, direction) +
        distanceRatioWeight *
          this.getItemDistanceRatio(distance, distanceToFarthestItem, distanceToClosestItem, opts?.preferFarthest);

      if (itemMatchRatio > newActiveItemMatchRatio) {
        newActiveItem = item;
        newActiveItemMatchRatio = itemMatchRatio;
      }
    }

    return newActiveItem;
  }

  private getActivatableItems(): KeyboardNavigationItemDirective[] {
    return [...this.items].filter(item => item.isVisible() && !item.disabled);
  }

  /**
   * This method returns a cosine of the angle between "item direction vector" and "direction vector", with all
   * negative values limited to 0.
   * It means that it'll return 1 when directions match (angle between them is 0 degrees)
   * and 0 when they don't (angle between them >=90 degrees).
   */
  private getAlignmentRatio(vector: DirectionVector, direction: KeyboardNavigationDirection): number {
    const vectorLength = this.getDirectionVectorLength(vector);

    if (vectorLength === 0) {
      return 1;
    }

    const directionVector = DIRECTION_TO_VECTOR_MAP.get(direction)!;
    const cos = (vector.x * directionVector.x + vector.y * directionVector.y) / vectorLength;

    return Math.max(cos, 0);
  }

  /**
   * This method returns value between 0 and 1.
   * When `preferFarthest` is `false` 1 corresponds to the closest item and 0 to farthest.
   * When `preferFarthest` is `true` 1 corresponds to the farthest item and 0 to closest.
   */
  private getItemDistanceRatio(
    distanceToItem: number,
    distanceToFarthestItem: number,
    distanceToClosestItem: number,
    preferFarthest = false,
  ): number {
    if (distanceToFarthestItem === distanceToClosestItem) {
      return 1;
    }

    return preferFarthest
      ? (distanceToItem - distanceToClosestItem) / (distanceToFarthestItem - distanceToClosestItem)
      : (distanceToFarthestItem - distanceToItem) / (distanceToFarthestItem - distanceToClosestItem);
  }

  private getDirectionVectorLength(v: DirectionVector): number {
    return Math.sqrt(v.x * v.x + v.y * v.y);
  }

  private handlePageKeydown = (event: KeyboardEvent): boolean => {
    const direction = KEY_TO_DIRECTION_MAP.get(event.key);

    if (!direction) {
      // Handle only navigation keys
      return false;
    }

    if (this.activeItem) {
      const {elementTag} = this.activeItem;

      if (elementTag === 'textarea') {
        /*
         * Allow to move caret inside of a `textarea` element.
         * In this case keyboard navigation can be done only via `Tab` key.
         */
        return false;
      }

      if (
        elementTag === 'input' &&
        (direction === KeyboardNavigationDirection.LEFT || direction === KeyboardNavigationDirection.RIGHT)
      ) {
        /*
         * Allow to move caret left/right inside of an `input` element.
         * In this case keyboard navigation can be done via ArrowUp/ArrowDown and `Tab` keys.
         */
        return false;
      }
    }

    this.ngZone.run(() => {
      this.navigate(direction);
    });

    return true;
  };
}
