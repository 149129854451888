import _ from 'lodash';

import {config} from '../config';

import {OutputSchemaField} from './types';

interface Settings {
  version: string;
  clobbering_info_url: string;
  forEachSchema: OutputSchemaField[];
  repeatSchema: OutputSchemaField[];
  catchSchema: OutputSchemaField[];
  jobContextSchema: OutputSchemaField[];
  firstOrderApps: string[];
  popularApps: string[];
  sentryDsn: string;
  stripePublishableKey: string;
  internalRecipesApiKey: string;
  webhooksOrigin: string;
  googleAnalyticsAppId: string;
  websiteUrl: string;
  workatoAwsAccountId: string;
  sentryEnvironment: string;
  /**
   * Uppercase region short name, e.g. "US". Is set using SITE_REGION env variable
   * Full list of environments: https://github.com/workato/k8s-apps-definitions/tree/master/apps/workato/prod
   */
  region: 'US' | 'EU' | 'SG' | 'JP';
}

export const appSettings: Settings = {
  version: window.Workato.version,
  ..._.pick(
    config,
    'clobbering_info_url',
    'managed_accounts_info',
    'forEachSchema',
    'repeatSchema',
    'catchSchema',
    'jobContextSchema',
    'firstOrderApps',
    'popularApps',
    'sentryDsn',
    'stripePublishableKey',
    'internalRecipesApiKey',
    'webhooksOrigin',
    'googleAnalyticsAppId',
    'websiteUrl',
    'workatoAwsAccountId',
    'sentryEnvironment',
    'region',
  ),
};
