import {Injectable} from '@angular/core';
import _ from 'lodash';
import {DateTime} from 'luxon';

import {subscriptions} from '@shared/services/subscriptions';

import {AuthUser, Workspace} from '../../services/auth-user';
import {AppMenuOption, AppMenuOptionGroup, AppMenuOptions} from '../app-menu/app-menu.types';
import {AccountEnvironment, Environment} from '../../services/account-environments.types';
import {ACCOUNT_ENVIRONMENT_LABELS, AccountEnvironmentService} from '../../services/account-environments.service';

const LAST_ACCESSED_GROUP_VISIBILITY_THRESHOLD = 5;
const LAST_ACCESSED_GROUP_SIZE_LIMIT = 5;
const ENV_DOCS_URL = 'https://docs.workato.com/features/environments.html#environment-basics';
const ENV_UNAVAILABLE_TOOLTIP = 'Contact admin to request access';
const AGENTIC_PRODUCT: Product = {
  id: 'agentic',
  name: 'Agentic',
};
const ORCHESTRATE_PRODUCT: Product = {
  id: 'orchestrate',
  name: 'Orchestrate',
};

export type ProductId = 'orchestrate' | 'agentic';
export interface Product {
  id: ProductId;
  name: string;
}

@Injectable({providedIn: 'root'})
export class AppContextSwitcherService {
  private readonly ownWorkspace: Workspace;
  private readonly devEnvironment: AccountEnvironment;
  private readonly testEnvironment: AccountEnvironment;
  private readonly prodEnvironment: AccountEnvironment;
  private readonly environment?: AccountEnvironment;

  private subs = subscriptions();

  constructor(
    private authUser: AuthUser,
    private accountEnvironmentService: AccountEnvironmentService,
  ) {
    this.environment = this.accountEnvironmentService.allEnvironments.find(({id}) => id === this.authUser.id);
    /* When user does not have environments enabled and cannot switch them, we can use fake id here */
    this.devEnvironment = {
      id: 0,
      name: ACCOUNT_ENVIRONMENT_LABELS.dev,
      type: 'dev',
      theme: 'dev',
      shortTypeName: 'dev',
    };
    this.testEnvironment = {
      id: 1,
      name: ACCOUNT_ENVIRONMENT_LABELS.test,
      type: 'test',
      theme: 'test1',
      shortTypeName: 'test',
    };
    this.prodEnvironment = {
      id: 2,
      name: ACCOUNT_ENVIRONMENT_LABELS.prod,
      type: 'prod',
      theme: 'prod',
      shortTypeName: 'prod',
    };
    this.ownWorkspace = {
      id: this.authUser.id,
      name: this.authUser.logged_workspace_name,
      avatar_url: this.authUser.workspace_image,
      group_name: '',
      team_last_sign_in_at: DateTime.now().toISO(),
    };

    this.subs.add(
      this.authUser.currentWorkspaceChange$.subscribe(() => {
        this.ownWorkspace.name = this.authUser.logged_workspace_name;
        this.ownWorkspace.avatar_url = this.authUser.workspace_image;
      }),
    );
  }

  get hasProductSwitcher(): boolean {
    return this.authUser.oem_admin || !this.authUser.oem_user;
  }

  get hasEnvironmentSwitcher(): boolean {
    return !this.authUser.oem_admin && !(this.authUser.oem_user && !this.hasEnvironments);
  }

  get hasEnvironments(): boolean {
    return Boolean(this.authUser.all_environments?.length);
  }

  get currentWorkspace(): Workspace {
    return this.authUser.team_session ? this.authUser.current_team! : this.ownWorkspace;
  }

  get currentEnvironment(): AccountEnvironment {
    return this.environment ?? this.devEnvironment;
  }

  get currentProduct(): Product {
    return this.authUser.agentic && this.authUser.hasPrivilege('workato_genie.all')
      ? AGENTIC_PRODUCT
      : ORCHESTRATE_PRODUCT;
  }

  buildWorkspaceOptions(onlyCurrent = false): AppMenuOptions<Workspace['id'], Workspace> {
    if (onlyCurrent) {
      return [this.mapWorkspaceOption(this.currentWorkspace)];
    }

    const currentWorkspace = this.currentWorkspace;
    const workspaces = [...this.authUser.availableWorkspaces];

    if (!workspaces.find(({id}) => id === currentWorkspace.id)) {
      workspaces.unshift(currentWorkspace);
    }

    const groups: Array<AppMenuOptionGroup<Workspace['id'], Workspace>> = [];

    let myWorkspace: Workspace | undefined;
    let recentWorkspaces: Workspace[] | undefined;
    let otherWorkspaces: Workspace[];

    if (this.authUser.team_session) {
      myWorkspace = _.remove(workspaces, ({id}) => this.authUser.isLoggedUser(id))[0];
    }

    if (workspaces.length > LAST_ACCESSED_GROUP_VISIBILITY_THRESHOLD) {
      const [accessed, nonAccessed] = _.partition(workspaces, workspace => workspace.team_last_sign_in_at);
      const allRecentWorkspaces = _.orderBy(accessed, 'team_last_sign_in_at', 'desc');

      recentWorkspaces = _.take(allRecentWorkspaces, LAST_ACCESSED_GROUP_SIZE_LIMIT);
      otherWorkspaces = _.drop(allRecentWorkspaces, LAST_ACCESSED_GROUP_SIZE_LIMIT).concat(nonAccessed);
    } else {
      otherWorkspaces = workspaces;
    }

    otherWorkspaces.sort((ws1, ws2) => ws1.name.localeCompare(ws2.name));

    if (myWorkspace) {
      groups.push(this.mapWorkspaceOptionGroup('My workspace', [myWorkspace]));
    }

    if (recentWorkspaces?.length) {
      groups.push(this.mapWorkspaceOptionGroup('Recent', recentWorkspaces));
    }

    if (groups.length && otherWorkspaces.length) {
      groups.push(this.mapWorkspaceOptionGroup('Other workspaces', otherWorkspaces));
    }

    return groups.length ? groups : otherWorkspaces.map(this.mapWorkspaceOption);
  }

  buildEnvironmentOptions(): Array<AppMenuOption<Environment['id'], AccountEnvironment>> {
    if (!this.hasEnvironments) {
      return [
        this.mapAccountEnvironmentOption(this.devEnvironment, false),
        this.mapAccountEnvironmentOption(this.testEnvironment, true),
        this.mapAccountEnvironmentOption(this.prodEnvironment, true),
      ];
    }

    return this.authUser.all_environments!.map(env => this.mapEnvironmentOption(env));
  }

  buildProductOptions(): Array<AppMenuOption<ProductId, Product>> {
    const options: Array<AppMenuOption<ProductId, Product>> = [
      {
        title: 'Orchestrate',
        value: 'orchestrate',
        context: {id: 'orchestrate', name: 'Orchestrate'},
      },
    ];

    if (this.authUser.hasPrivilege('workato_genie.all')) {
      options.push({
        title: 'Agentic',
        value: 'agentic',
        context: {id: 'agentic', name: 'Agentic'},
      });
    }

    return options;
  }

  private get isCollaborator(): boolean {
    return (
      this.authUser.federation_child ||
      this.authUser.logged_user_federation_child ||
      this.authUser.id !== this.authUser.logged_user_id
    );
  }

  private mapWorkspaceOptionGroup(
    title: string,
    workspaces: Workspace[],
  ): AppMenuOptionGroup<Workspace['id'], Workspace> {
    return {
      title,
      options: workspaces.map(this.mapWorkspaceOption),
    };
  }

  private mapWorkspaceOption(workspace: Workspace): AppMenuOption<Workspace['id'], Workspace> {
    return {
      title: workspace.name,
      value: workspace.id,
      context: workspace,
    };
  }

  private mapEnvironmentOption(environment: Environment): AppMenuOption<Environment['id'], AccountEnvironment> {
    const env = this.accountEnvironmentService.allEnvironments.find(({id}) => id === environment.id)!;
    const locked = !this.authUser.available_environments.find(({id}) => id === environment.id);

    return this.mapAccountEnvironmentOption(env, locked);
  }

  private mapAccountEnvironmentOption(
    accountEnvironment: AccountEnvironment,
    locked: boolean,
  ): AppMenuOption<Environment['id'], AccountEnvironment> {
    return {
      title: accountEnvironment.name,
      value: accountEnvironment.id,
      locked,
      lockedUrl: locked && !this.isCollaborator ? ENV_DOCS_URL : undefined,
      lockedTooltip: locked && this.isCollaborator ? ENV_UNAVAILABLE_TOOLTIP : undefined,
      context: accountEnvironment,
    };
  }
}
