import {Directive, Input, TemplateRef} from '@angular/core';

import {AppMenuOption, AppMenuOptions} from '../app-menu/app-menu.types';

export interface AppMenuItemIconContext<TValue = unknown, TContext extends object | undefined = undefined> {
  $implicit: AppMenuOption<TValue, TContext>;
}

@Directive({
  selector: 'ng-template[wAppMenuItemIcon]',
  standalone: true,
})
export class AppMenuItemIconDirective<TValue = unknown, TContext extends object | undefined = undefined> {
  @Input('wAppMenuItemIcon') options: AppMenuOptions<TValue, TContext>;

  static ngTemplateContextGuard<TValue, TContext extends object | undefined = undefined>(
    dir: AppMenuItemIconDirective<TValue, TContext>,
    ctx: any,
  ): ctx is AppMenuItemIconContext<TValue, TContext> {
    return true;
  }

  constructor(public templateRef: TemplateRef<AppMenuItemIconContext<TValue, TContext>>) {}
}
