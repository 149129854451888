import {ChangeDetectionStrategy, Component, ViewEncapsulation} from '@angular/core';
import {trigger} from '@angular/animations';

import {fadeInOutWithSlide} from '@shared/animations/fade-in-out-with-slide.animation';

@Component({
  selector: 'w-app-header-dropdown',
  templateUrl: './app-header-dropdown.component.html',
  styleUrl: './app-header-dropdown.component.scss',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  animations: [trigger('fadeInOut', fadeInOutWithSlide)],
})
export class AppHeaderDropdownComponent {}
