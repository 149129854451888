import {ChangeDetectionStrategy, Component, TemplateRef, ViewEncapsulation} from '@angular/core';

import {SearchFieldComponent} from '@shared/modules/search-fields/search-field/search-field.component';
import {PluralPipe} from '@shared/pipes/plural.pipe';

import {SharedModule} from '../../shared.module';
import {AppMenuItemComponent} from '../app-menu-item/app-menu-item.component';
import {AppMenuItemIconContext} from '../app-menu-item/app-menu-item-icon.directive';

import {AbstractAppMenu} from './abstract-app-menu';
import {AppMenuOption} from './app-menu.types';

const MIN_OPTIONS_FOR_SEARCH = 6;

@Component({
  selector: 'w-app-menu',
  templateUrl: './app-menu.component.html',
  styleUrl: './app-menu.component.scss',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [SharedModule, SearchFieldComponent, AppMenuItemComponent, PluralPipe],
})
export class AppMenuComponent<
  TValue = unknown,
  TContext extends object | undefined = undefined,
> extends AbstractAppMenu<TValue, TContext> {
  filter = '';
  filteredOptions: Array<AppMenuOption<TValue, TContext>> = [];

  get hasSearch(): boolean {
    return this.flatOptions.length >= MIN_OPTIONS_FOR_SEARCH;
  }

  get iconTemplate(): TemplateRef<AppMenuItemIconContext<TValue, TContext>> | undefined {
    return this.itemIconTemplateDirective?.templateRef;
  }

  isSelected(option: AppMenuOption<TValue, TContext>): boolean {
    return option.value === this.selectedValue;
  }

  selectOption(option: AppMenuOption<TValue, TContext>) {
    this.selectedValueChange.emit(option.value);
  }

  filterOptions(query: string) {
    this.filter = query;

    if (query) {
      this.filteredOptions = this.flatOptions.filter(option =>
        option.title.toLowerCase().includes(query.toLowerCase()),
      );
    } else {
      this.filteredOptions = [];
    }
  }
}
