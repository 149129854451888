<img
  *ngIf="imgUrl; else initialsTmpl"
  class="user-avatar__image"
  [class.user-avatar__image_cover]="imgCover"
  [src]="imgUrl"
  [alt]="userName"
  [ngStyle]="imageStyle"
/>
<ng-template #initialsTmpl>
  <w-user-initials-avatar
    class="user-avatar__initials"
    [name]="userName"
    [size]="size"
    [borderRadius]="borderRadius"
    [border]="border"
  />
</ng-template>
