import {ElementRef, Injectable, OnDestroy} from '@angular/core';
import _ from 'lodash';
import {CookieService} from 'ngx-cookie-service';
import {BehaviorSubject, Observable, Subscription, combineLatest, fromEvent, map, merge, switchMap} from 'rxjs';
import {distinctUntilChanged, shareReplay, startWith} from 'rxjs/operators';
import {DateTime} from 'luxon';

import {MixpanelService} from '@shared/services/mixpanel';

import {OemService} from '../modules/oem/oem.service';
import {AuthUser} from '../services/auth-user';
import {OEM_THEME, PageService} from '../services/page.service';

import {
  CssColorVariableName,
  CssDerivedVariableName,
  CssEmbeddedVariableName,
  CssFontVariableName,
  CssTheme,
  CssThemeVariableName,
  ResolvedTheme,
  ThemeOverrides,
} from './theme-types';
import {DARK_THEME} from './themes/dark-theme';
import {LIGHT_THEME} from './themes/light-theme';
import {DERIVED_VARIABLES, EMBEDDED_VARIABLES, FONT_VARIABLES, THEME_VARIABLES} from './theme-variables';

const PREFERRED_THEME_COOKIE_NAME = '_workato_preferred_theme';

export type ThemeKey = 'light' | 'dark';
export type ThemePreference = ThemeKey | 'match-browser';
export type ThemeClass = `${ThemeKey}-theme`;

@Injectable({
  providedIn: 'root',
})
export class ThemeService implements OnDestroy {
  themeKey$: Observable<ThemeKey>;
  themePreference$: Observable<ThemePreference>;

  private readonly styleElement: HTMLStyleElement;
  private readonly themes: {[key in ThemeKey]: ResolvedTheme};
  private overrides?: ThemeOverrides;
  private subscription?: Subscription;

  private _themePreference = new BehaviorSubject<ThemePreference>('light');
  private _localThemes = new BehaviorSubject(new Map<HTMLElement, ThemeKey>());

  constructor(
    private oemService: OemService,
    private authUser: AuthUser,
    private pageService: PageService,
    private cookieService: CookieService,
    private mixpanelService: MixpanelService,
  ) {
    this.themes = {
      dark: this.resolveThemeByKey('dark'),
      light: this.resolveThemeByKey('light'),
    };
    this.themePreference$ = this._themePreference.asObservable();
    this.themeKey$ = this.themePreference$.pipe(switchMap(this.getThemeKeyAsObservable), shareReplay(1));
    this.styleElement = document.createElement('style');
    document.head.appendChild(this.styleElement);
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
    this.styleElement?.remove();
  }

  get themeKey(): ThemeKey {
    return this.getThemeKey(this._themePreference.value);
  }

  init() {
    this.oemService.fontUrls.forEach(url => this.loadFont(url));
    this.setTheme(this.getStoredPreference());
    this.trackTheme();
    this.subscription = this.themeKey$.subscribe(themeKey => this.updateTheme(themeKey));

    if (this.oemService.themeEnabled) {
      this.pageService.addClass(OEM_THEME);
    }
  }

  setTheme(preference: ThemePreference, overrides?: ThemeOverrides) {
    if (this.authUser.authenticated && !this.authUser.oem_user && !overrides) {
      this.cookieService.set(PREFERRED_THEME_COOKIE_NAME, preference, {
        path: '/',
        // Make cookie persistent across browser closing
        expires: DateTime.now().plus({year: 1}).toJSDate(),
        domain: PRODUCTION ? '.workato.com' : '',
      });
    }

    this.overrides = overrides;
    this.themes.dark = this.resolveThemeByKey('dark');
    this.themes.light = this.resolveThemeByKey('light');
    this._themePreference.next(preference);
  }

  getThemeKeyFor(elementOrRef: ElementRef<HTMLElement> | HTMLElement): ThemeKey {
    if (!this._localThemes.value.size) {
      return this.themeKey;
    }

    let element: HTMLElement | null = elementOrRef instanceof HTMLElement ? elementOrRef : elementOrRef.nativeElement;

    while (element) {
      if (this._localThemes.value.has(element)) {
        return this._localThemes.value.get(element)!;
      }

      element = element.parentElement;
    }

    return this.themeKey;
  }

  getThemeKeyAsObservableFor(elementOrRef: ElementRef<HTMLElement> | HTMLElement): Observable<ThemeKey> {
    return combineLatest([this._localThemes, this.themeKey$]).pipe(
      map(() => this.getThemeKeyFor(elementOrRef)),
      distinctUntilChanged(),
    );
  }

  setLocalTheme(themeKey: ThemeKey, ...elements: HTMLElement[]) {
    if (!elements.length) {
      return;
    }

    elements.forEach(element => {
      // Set default font color to be used for inheritance
      element.style.color = `var(--${'text-primary' satisfies CssColorVariableName})`;
      element.style.colorScheme = themeKey;

      Object.entries(this.themes[themeKey]).forEach(([key, value]) => {
        element.style.setProperty(`--${key}`, value);
      });

      element.classList.remove(...this.themeClasses);
      element.classList.add(this.getThemeClass(themeKey));

      this._localThemes.value.set(element, themeKey);
    });

    this._localThemes.next(this._localThemes.value);
  }

  deleteLocalTheme(...elements: HTMLElement[]) {
    if (!elements.some(element => this._localThemes.value.has(element))) {
      return;
    }

    elements.forEach(element => {
      this._localThemes.value.delete(element);
    });
    this._localThemes.next(this._localThemes.value);
  }

  getColor(variableName: CssColorVariableName, elementOrRef: ElementRef<HTMLElement> | HTMLElement): string {
    return this.themes[this.getThemeKeyFor(elementOrRef)][variableName];
  }

  loadFont(url: string) {
    if (document.querySelector(`link[href='${url}']`)) {
      return;
    }

    const link = document.createElement('link');

    link.rel = 'stylesheet';
    link.href = url;
    document.getElementsByTagName('head')[0].appendChild(link);
  }

  private get systemThemeKey(): ThemeKey {
    return window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
  }

  private get themeClasses(): ThemeClass[] {
    return Object.keys(this.themes).map(this.getThemeClass);
  }

  private getThemeClass(themeKey: ThemeKey): ThemeClass {
    return `${themeKey}-theme`;
  }

  private getStoredPreference(): ThemePreference {
    const storedPreference = this.authUser.oem_user
      ? this.oemService.baseThemeKey
      : (this.cookieService.get(PREFERRED_THEME_COOKIE_NAME) as ThemePreference | '');

    return this.authUser.authenticated && storedPreference ? storedPreference : 'light';
  }

  private resolveThemeByKey(themeKey: ThemeKey): ResolvedTheme {
    return this.resolveTheme(themeKey === 'dark' ? DARK_THEME : LIGHT_THEME, {
      ...this.oemService.getOverridesForTheme(themeKey),
      ...this.overrides,
    });
  }

  /*
   * This method transforms the base CSS theme(light or dark) into the final set of CSS variables.
   * It resolves variable references, calculates derived(rgba) values, accounts for OEM overrides.
   */
  private resolveTheme(theme: CssTheme, overrides: ThemeOverrides = {}): ResolvedTheme {
    const variables: ResolvedTheme = {} as any;

    Object.keys(THEME_VARIABLES).forEach((variableName: CssThemeVariableName) => {
      variables[variableName] = this.resolveThemeValue(variableName, {...theme, ..._.pickBy(overrides, Boolean)});
    });

    Object.keys(DERIVED_VARIABLES).forEach((variableName: CssDerivedVariableName) => {
      const variableValue = DERIVED_VARIABLES[variableName].getValue(variables);

      if (variableValue) {
        variables[variableName] = variableValue;
      }
    });

    Object.keys(EMBEDDED_VARIABLES).forEach((variableName: CssEmbeddedVariableName) => {
      const variableValue = overrides[variableName];
      const {defaultValue} = EMBEDDED_VARIABLES[variableName];

      variables[variableName] = this.oemService.embedded && variableValue ? variableValue : defaultValue!;
    });

    Object.keys(FONT_VARIABLES).forEach((variableName: CssFontVariableName) => {
      const variableValue = overrides[variableName];

      variables[variableName] = variableValue || FONT_VARIABLES[variableName].defaultValue!;
    });

    return variables;
  }

  private resolveThemeValue(variableName: CssThemeVariableName, theme: CssTheme): string {
    const value = theme[variableName];

    return typeof value === 'string' ? value : this.resolveThemeValue(value.refName, theme);
  }

  private updateTheme(themeKey: ThemeKey) {
    const stylesheet = this.styleElement.sheet!;

    if (stylesheet.cssRules.length) {
      stylesheet.deleteRule(0);
    }

    stylesheet.insertRule(
      `:root {
        color-scheme: ${themeKey};
        ${Object.entries(this.themes[themeKey])
          .map(([key, value]) => `--${key}: ${value};`)
          .join('\n')}
      }`,
    );
    this.pageService.disableTransitions();
    this.pageService.removeClass(this.themeClasses.join(' '));
    this.pageService.addClass(this.getThemeClass(themeKey));
  }

  private getThemeKey(preference: ThemePreference): ThemeKey {
    if (window.matchMedia('print').matches) {
      return 'light';
    }

    if (preference !== 'match-browser') {
      return preference;
    }

    return this.systemThemeKey;
  }

  private trackTheme() {
    this.mixpanelService.track('Theme', {
      in_settings: this._themePreference.value,
      in_system: this.systemThemeKey,
      actual: this.themeKey,
    });
  }

  private getThemeKeyAsObservable = (preference: ThemePreference): Observable<ThemeKey> =>
    merge(
      fromEvent(window.matchMedia('(prefers-color-scheme: dark)'), 'change'),
      fromEvent(window.matchMedia('print'), 'change'),
    ).pipe(
      startWith(null),
      map(() => this.getThemeKey(preference)),
    );
}
