import _ from 'lodash';
import {Observable, Subject} from 'rxjs';
import {Injectable} from '@angular/core';
import {v4 as generateUuid} from 'uuid';
import {HttpClient} from '@angular/common/http';
import URL from 'url-parse';
import {DateTime, Settings} from 'luxon';

import {AbstractAuthService} from '@shared/services/abstract-auth.service';
import {HttpResource} from '@shared/services/http-resource';
import {HexColor} from '@shared/types/lib';

import {Adapter, AdaptersNames, ClientSessionId, DateTimeUserFormat, TimeZone, User} from '../types';
import {OemConfig} from '../modules/oem/oem.types';
import {PortalSettings} from '../pages/people-tasks/people-task.types';
import {CustomOauthKey, CustomOauthKeyShort} from '../pages/custom-oauth-keys/custom-oauth-keys.types';

import {AccountPeriodData} from './account-period.service';
import {
  AuthUserAdHocFeatures,
  AuthUserFeatureFlags,
  AuthUserInvitation,
  AuthUserPrivilege,
  AuthUserPrivilegeExpression,
  AuthUserRole,
} from './auth-user.types';
import {Environment} from './account-environments.types';

export interface Workspace {
  id: number;
  name: string;
  group_name: string;
  avatar_url: string;
  team_last_sign_in_at: string | null;
}

export interface AvailableTool {
  id:
    | 'service_console'
    | 'workato_schema'
    | 'custom_adapter_sdk'
    | 'custom_oauth_key'
    | 'lookup_table'
    | 'workato_template'
    | 'opa'
    | 'people_task'
    | 'account_property'
    | 'topic'
    | 'package'
    | 'team'
    | 'workbot'
    | 'runtime_connections'
    | 'logging_service'
    | 'file_storage'
    | 'lcap_portal'
    | 'usage_insights'
    | 'data_pipeline';
  name: string;
  href: string;
}

interface AuthUserRequestParams {
  oem_vendor_id?: string;
  oem_account_id?: string;
}

type SecureGatewayTunnelName = string;
type SecureGatewayTunnelId = number;
type SecureGatewayManagedConnectionEnabled = boolean;

export type AuthUserSecureGatewayTunnel = [
  SecureGatewayTunnelName,
  SecureGatewayTunnelId,
  SecureGatewayManagedConnectionEnabled,
];

export type AuthUserPrivileges =
  | AuthUserPrivilege
  | AuthUserPrivilegeExpression
  | Array<AuthUserPrivilege | AuthUserPrivilegeExpression>;

/*
 * `PricingType` value `null` indicates that we're unable to identify pricing type within the current accounting period
 * This situation is possible when the account doesn't have nor billable_flow_limit neither effective_action_limit
 */
export type PricingType = 'tbp' | 'rbp' | null;

@Injectable({
  providedIn: 'root',
})
export class AuthUser implements AbstractAuthService {
  clientSessionId: ClientSessionId = generateUuid();
  signInUrl = '/users/sign_in';
  authenticated: boolean;
  id: User['id'];
  name: User['name'];
  logged_user_id: User['id'];
  logged_name: User['name'];
  logged_workspace_name: string;
  email: string;
  confirmed_at: string | null;
  unconfirmed_email: string | null;
  first_name: string;
  last_name: string;
  in_trial: boolean;
  trial_expired: boolean;
  trial_ends_at: string;
  upgrade_url: string;
  datetime_format: DateTimeUserFormat;

  membership: {
    name: string;
    type: string;
    period: string;
    audience: string;
    min_poll_interval: number;
    poll_interval: number;
    accounting_period_length: 'month' | 'year';
  };

  membership_label: 'business' | 'limited_edition' | 'enterprise' | 'standard';

  ad_hoc_adapters?: AdaptersNames;
  worker_concurrency_enabled: boolean;
  max_worker_concurrency: number;
  api_concurrency_soft_limit: number;

  private_recipes: {
    enabled: boolean;
    default: boolean;
  };

  secure_gateway_tunnels: AuthUserSecureGatewayTunnel[];
  team_session: boolean;
  custom_oauth_keys: CustomOauthKeyShort[];
  created_at: string;
  webhookId: string;
  show_people_tasks_onboarding?: boolean;
  show_action_usage_notification?: boolean;
  action_usage_notification_key: string;
  connected_apps: AdaptersNames;
  disabled_apps?: AdaptersNames;
  partner_adapters_seen?: AdaptersNames;
  error_notification_emails: string;
  federation: boolean;
  federation_child: boolean;
  logged_user_federation_child: boolean;
  federation_manager: boolean;
  oem_config?: OemConfig;
  oem_user: boolean;
  oem_admin: boolean;
  oem_customer_with_generated_email: boolean;
  oem_generated_email_replacement: string;
  oem_customer_manager: boolean;
  team_first_time: boolean;
  disable_password_authentication: boolean;
  oem_welcome_shown: boolean;
  roles: AuthUserRole[];
  twoFaEnabled: boolean;
  accountPropertiesLimit: number;
  profile_image: string;
  workspace_image: string;
  jobs_debug_enabled: boolean;
  coworker_enabled: boolean;
  can_make_recipes_public: boolean;
  aws_workato_account_id: number;
  aws_iam_external_id: string;
  pricing_type: PricingType;
  max_hvr_tier1: number;
  max_hvr_tier2: number;
  max_hvr_tier3: number;
  workbots_limit: number | null;
  max_workflow_app_user: number | null;
  opa_limit: number | null;
  accounting_period: AccountPeriodData;
  enforce_transaction_quota: boolean;
  workflow_user: boolean;
  workflow_portal: PortalSettings;
  timezone: TimeZone;
  account_timezone: string;
  teams: Workspace[];
  current_team: Workspace | null;
  available_tools: AvailableTool[];
  // This field would be empty if user has access to a single environment only
  environments: Environment[];
  // This field would be null if user has access to a single environment only
  current_environment: Environment | null;
  has_unavailable_tools: boolean;
  default_notification_emails: string | null;
  technical_contact_emails: string | null;
  deployment_reviews_enabled: boolean;

  // <-- Specific to API portals feature
  auth_type?: 'token' | 'jwt' | 'oauth2' | 'oidc';

  api_portal?: {
    name: string;
    logo: string;
    brand_color: HexColor;
  };
  // -->

  support_session: {
    id: number;
    user_name: string;
    support_user_name: string;
    started_at: string;
    duration: number;
    max_duration: number;
  };

  count_of_projects?: number;
  invitations: AuthUserInvitation[] | null;
  secret_manager_scope: 'workspace' | 'projects';
  aws_iam_external_id_scope: 'workspace' | 'projects';
  ad_hoc_features?: AuthUserAdHocFeatures;
  feature_flags: AuthUserFeatureFlags;
  // This field lists all the environments within the workspace, even those unavailable for the current user
  all_environments?: Environment[];
  // This field is similar to `environments`, but it returns proper data even if user has access to a single env only
  available_environments: Environment[];
  deployable_environments: Environment[];
  reviewable_environments: Environment[];
  data_pills_version: 1 | 2 | null; // null is default value, meaning pills v.1 will be used
  agentic: boolean;
  agentic_url: string;
  orchestrate_url: string;

  hasPrivilege: (privileges: AuthUserPrivileges) => boolean = _.memoize((privileges: AuthUserPrivileges) => {
    const {operator, operands} = this.convertToPrivilegeExpression(privileges);

    return operands[operator === 'and' ? 'every' : 'some'](privilege =>
      typeof privilege === 'string' ? _.get(this, `privileges.${privilege}`) : this.hasPrivilege(privilege),
    );
  });

  hasRole = _.memoize((role: AuthUserRole) => _.includes(this.roles, role));

  currentWorkspaceChange$: Observable<Workspace>;
  workspacesChange$: Observable<Workspace[]>;

  private currentWorkspaceChange = new Subject<Workspace>();
  private workspacesChange = new Subject<Workspace[]>();
  private resource: HttpResource;
  private secureGatewayTunnelsResource: HttpResource;
  private initPromise?: Promise<void>;

  constructor(private http: HttpClient) {
    this.resource = new HttpResource(this.http, {
      url: '/web_api/auth_user.json',
    });

    this.secureGatewayTunnelsResource = new HttpResource(this.http, {
      url: '/web_api/secure_gateway_tunnels.json',
    });

    this.currentWorkspaceChange$ = this.currentWorkspaceChange.asObservable();
    this.workspacesChange$ = this.workspacesChange.asObservable();
  }

  get availableWorkspaces(): Workspace[] {
    return this.teams || [];
  }

  set availableWorkspaces(workspaces: Workspace[]) {
    this.teams = workspaces;
    this.workspacesChange.next(workspaces);
  }

  get unconfirmedEmail(): string | null {
    return this.unconfirmed_email || (!this.confirmed_at ? this.email : null);
  }

  get daysTillTrialEnds(): number {
    if (!this.in_trial) {
      return 0;
    }

    return Math.ceil(DateTime.fromISO(this.trial_ends_at).diffNow().as('days'));
  }

  init() {
    this.initPromise ||= this.loadAuthUser();

    return this.initPromise;
  }

  isLoggedUser(id: User['id']): boolean {
    return id === this.logged_user_id;
  }

  updateCurrentTeam(newValue: Partial<Workspace>) {
    const oldName = this.current_team?.name;

    this.current_team = {...this.current_team, ...newValue} as Workspace;

    const currentAvailableTeam = this.teams.find(({name}) => name === oldName);

    _.assign(currentAvailableTeam, newValue);

    if (!this.team_session) {
      if (newValue.name) {
        this.logged_workspace_name = newValue.name;
      }

      if (newValue.avatar_url) {
        this.workspace_image = newValue.avatar_url;
      }
    }

    this.currentWorkspaceChange.next(this.current_team);
  }

  async updateSecureGatewayTunnels() {
    let tunnels: AuthUserSecureGatewayTunnel[] = [];

    try {
      tunnels = (await this.secureGatewayTunnelsResource.get()).secure_gateway_tunnels;
    } finally {
      this.secure_gateway_tunnels = tunnels;
    }
  }

  addCustomOauthKey(key: CustomOauthKey) {
    this.custom_oauth_keys.push(key);
  }

  updateCustomOauthKey(key: CustomOauthKey) {
    const existingKey = this.custom_oauth_keys.find(({id}) => id === key.id);

    if (existingKey) {
      Object.assign(existingKey, key);
    } else {
      this.addCustomOauthKey(key);
    }
  }

  deleteCustomOauthKey(keyId: CustomOauthKey['id']) {
    this.custom_oauth_keys = this.custom_oauth_keys.filter(key => key.id !== keyId);
  }

  convertToPrivilegeExpression(privileges: AuthUserPrivileges): AuthUserPrivilegeExpression {
    if (typeof privileges === 'string') {
      return {
        operator: 'and',
        operands: [privileges],
      };
    } else if (Array.isArray(privileges)) {
      return {
        operator: 'and',
        operands: privileges,
      };
    } else {
      return privileges;
    }
  }

  hasFeatureFlag(feature: keyof AuthUserFeatureFlags): boolean {
    return Boolean(this.feature_flags[feature]);
  }

  hasAdHocFeatures(feature: keyof AuthUserAdHocFeatures): boolean {
    return Boolean(_.get(this.ad_hoc_features, feature));
  }

  hasAdHocAdapter(adapterName: Adapter['name']): boolean {
    return this.availableAdHocAdapters.includes(adapterName);
  }

  addAdHocAdapter(adapterName: Adapter['name']) {
    this.ad_hoc_adapters = [...this.availableAdHocAdapters, adapterName];
  }

  removeAdHocAdapter(adapterName: Adapter['name']) {
    this.ad_hoc_adapters = this.availableAdHocAdapters.filter(
      availableAdapterName => availableAdapterName !== adapterName,
    );
  }

  hasAccessToApiPlatform(): boolean {
    return this.hasPrivilege({
      operator: 'or',
      operands: [
        'service_console',
        'api_platform_dashboard',
        'api_platform_endpoints',
        'api_platform_clients',
        'api_platform_policies',
        'api_platform_settings',
      ],
    });
  }

  private get availableAdHocAdapters(): AdaptersNames {
    return this.ad_hoc_adapters || [];
  }

  private async loadAuthUser() {
    const parsedUrl = new URL(location.href, true);
    const params: AuthUserRequestParams = _.pick(parsedUrl.query, 'oem_vendor_id');

    // Special case: passing oem_account_id for failed auth with direct link page to get oem_config
    params.oem_account_id = window.Workato.pageData?.oemAccountId;

    let authUser: Partial<AuthUser> | undefined;

    try {
      authUser = await this.resource.getAll({query: params});
    } finally {
      Object.assign(this, authUser || {authenticated: false});

      this.timezone ||= Settings.defaultZone;

      if (TEST) {
        window.authUser = authUser;
      }
    }
  }
}
