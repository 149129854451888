import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
  ViewEncapsulation,
} from '@angular/core';

import {KeyboardNavigationItemDirective} from '@shared/modules/keyboard-navigation/keyboard-navigation-item.directive';
import {TrimmedTextComponent} from '@shared/components/trimmed-text/trimmed-text.component';

import {AppMenuOption} from '../app-menu/app-menu.types';
import {SharedModule} from '../../shared.module';

import {AppMenuItemIconContext} from './app-menu-item-icon.directive';

@Component({
  selector: 'w-app-menu-item',
  templateUrl: './app-menu-item.component.html',
  styleUrl: './app-menu-item.component.scss',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [SharedModule, KeyboardNavigationItemDirective, TrimmedTextComponent],
})
export class AppMenuItemComponent<TValue = unknown, TContext extends object | undefined = undefined> {
  @Input({required: true}) option: AppMenuOption<TValue, TContext>;
  @Input() selected = false;
  @Input() highlight?: string;
  @Input() iconTemplate?: TemplateRef<AppMenuItemIconContext<TValue, TContext>>;

  @Output() selectedChange = new EventEmitter<boolean>();

  selectOption() {
    if (!this.option.locked) {
      this.selectedChange.emit(true);
    }
  }
}
