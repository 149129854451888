import {AuthUser} from '../../services/auth-user';

import {
  CHART_BILLABLE_TASKS_GROUP,
  CHART_HVR_ELIGIBLE_TASKS_GROUP,
  CHART_SPECIAL_GROUP_NAME_MAPPER,
  CHART_SPECIAL_GROUP_TYPE_MAPPER,
  DEFAULT_SCOPE_GROUP_BY,
  HVR_TIER_1_THRESHOLD,
  HVR_TIER_2_THRESHOLD,
  HVR_TIER_3_THRESHOLD,
  NEAR_LIMIT_MULTIPLIER,
  SCOPE_ALLOWED_GROUP_BY,
} from './usage-dashboard.constants';
import {
  ChartResourceGroup,
  HvrRecipe,
  UsageChartInterval,
  UsageChartIntervalSpecialGroupKey,
  UsageGroupBy,
  UsageScopeFilter,
} from './usage-dashboard.types';

export function isAllowedGroupBy(scope: UsageScopeFilter, groupBy: UsageGroupBy): boolean {
  return SCOPE_ALLOWED_GROUP_BY[scope].includes(groupBy);
}

export function getDefaultGroupBy(scope: UsageScopeFilter): UsageGroupBy {
  return DEFAULT_SCOPE_GROUP_BY[scope];
}

export function getPercents(value: number, total: number): number {
  return Math.round((value / total) * 100);
}

export function isNearLimit(limit: number, value: number): boolean {
  return value >= limit * NEAR_LIMIT_MULTIPLIER;
}

export function isTBPV2MembershipPlan(user: AuthUser): boolean {
  return user.membership.type === 'tbp_v2';
}

export function hasApiProxyAccess(user: AuthUser): boolean {
  return user.hasAdHocFeatures('realtime_usage');
}

export function isSpecialGroupKey(groupKey: string): groupKey is UsageChartIntervalSpecialGroupKey {
  return groupKey === CHART_HVR_ELIGIBLE_TASKS_GROUP || groupKey === CHART_BILLABLE_TASKS_GROUP;
}

export function getSpecialGroupType(groupKey: UsageChartIntervalSpecialGroupKey): ChartResourceGroup {
  return CHART_SPECIAL_GROUP_TYPE_MAPPER[groupKey];
}

export function getSpecialGroupName(groupKey: UsageChartIntervalSpecialGroupKey): string {
  return CHART_SPECIAL_GROUP_NAME_MAPPER[groupKey];
}

export function isGroupedByHvrEligibleTasks(intervals: UsageChartInterval[]): boolean {
  return intervals.some(interval => interval.groups && interval.groups[CHART_HVR_ELIGIBLE_TASKS_GROUP] > 0);
}

export function isHvrEligibleForUpgrade(hvrRecipe: HvrRecipe): boolean {
  switch (hvrRecipe.tier) {
    case 1:
      return hvrRecipe.usage >= HVR_TIER_2_THRESHOLD;
    case 2:
      return hvrRecipe.usage >= HVR_TIER_3_THRESHOLD;
  }

  return false;
}

export function getHvrTier(taskCount: number): HvrRecipe['tier'] | null {
  if (taskCount >= HVR_TIER_3_THRESHOLD) {
    return 3;
  }

  if (taskCount >= HVR_TIER_2_THRESHOLD) {
    return 2;
  }

  if (taskCount >= HVR_TIER_1_THRESHOLD) {
    return 1;
  }

  return null;
}
