import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';

import {ElementResizeDirective} from '@shared/directives/element-resize.directive';

import {OemService} from '../../modules/oem/oem.service';

@Component({
  selector: 'w-app-sidebar-oem-logo',
  templateUrl: './app-sidebar-oem-logo.component.html',
  styleUrl: './app-sidebar-oem-logo.component.scss',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ElementResizeDirective],
})
export class AppSidebarOemLogoComponent {
  @Input() isExpanded = false;

  @Output() logoOffsetChange = new EventEmitter<number>();

  constructor(
    private oemService: OemService,
    private elementRef: ElementRef<HTMLElement>,
  ) {}

  get oemLogo(): string {
    return this.oemService.appLogo;
  }

  get oemLogoSmall(): string {
    return this.oemService.appLogoSmall;
  }

  get oemCompany(): string {
    return this.oemService.appName;
  }

  handleLogoOffsetChange(smallLogoWidth: number) {
    const containerWidth = this.elementRef.nativeElement.offsetWidth;

    this.logoOffsetChange.emit((containerWidth - smallLogoWidth) / 2);
  }
}
