import {UrlSegment} from '@angular/router';

import {RouterUrl} from '@shared/types/angular';
import {replaceString} from '@shared/utils/replace-string';
import {stringifyUrlQuery} from '@shared/utils/stringify-url-query';

export function stringifyRouterUrl(value: RouterUrl | null | undefined): string {
  if (!value) {
    return '';
  }

  const {path, query, fragment} = value;
  const pathname = stringifyRouterPath(path);

  return `${pathname}${stringifyUrlQuery(query)}${fragment ? `#${fragment}` : ''}`;
}

export function stringifyRouterPath(path: RouterUrl['path']): string {
  if (!path) {
    return '';
  }

  return typeof path === 'string' ? path : path.map(stringifyUrlSegment).join('/');
}

function stringifyUrlSegment(urlSegment: UrlSegment): string {
  return replaceString(String(urlSegment), '/', {
    replaceUnmatched: str => encodeURIComponent(str),
  });
}
