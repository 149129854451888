import {Injectable} from '@angular/core';

import {Overlay} from '@shared/modules/overlay/overlay.service';

import {SplashScreenComponent} from '../components/splash-screen/splash-screen.component';

@Injectable({
  providedIn: 'root',
})
export class WorkspacesService {
  constructor(private overlay: Overlay) {}

  showWorkspaceSwitchSlashScreen(workspaceName: string) {
    this.overlay.show(SplashScreenComponent, {
      props: {message: `Switching to ${workspaceName} account.`},
    });
  }

  switchToWorkspace(workspaceId: number, teamName: string) {
    const id = encodeURIComponent(workspaceId);
    const name = encodeURIComponent(teamName);

    window.location.href = `/users/switch_team?team_id=${id}&team_name=${name}`;
  }
}
