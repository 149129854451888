<div class="app-user-menu">
  <div class="app-user-menu__name">
    <w-user-avatar class="app-user-menu__avatar" [imgUrl]="userAvatar" [userName]="userName" [size]="36" />
    {{ userName }}
  </div>

  <div class="app-user-menu__content">
    @if (!isOemUser) {
      <div class="app-user-menu__section-header">Theme</div>

      <div class="app-user-menu__item app-user-menu__item_group">
        @for (theme of themes; track theme.id) {
          <button
            class="app-user-menu__button"
            [class.app-user-menu__button_selected]="(themePreference$ | async) === theme.id"
            type="button"
            [attr.aria-label]="theme.name + ' theme'"
            (click)="selectTheme(theme.id)"
            wMixpanel="Header navigation: Select {{ theme.name }} theme"
            [wKeyboardNavigationItem]="{origin: 'left'}"
          >
            <w-svg-icon class="app-user-menu__icon" [iconId]="theme.iconId" [size]="16" />
            <w-svg-icon class="app-user-menu__active-icon" [iconId]="theme.activeIconId" [size]="16" />
            {{ theme.name }}
          </button>
        }
      </div>
    }

    <hr class="app-user-menu__separator" />

    @if (isAgentic) {
      <a
        class="app-user-menu__item app-user-menu__item_link"
        [href]="profileHref"
        (click)="linkClick.emit($event)"
        wMixpanel="Header navigation: Profile settings"
        [wKeyboardNavigationItem]="{origin: 'left'}"
      >
        <ng-container *ngTemplateOutlet="profileLinkContentTmpl" />
      </a>
    } @else {
      <a
        class="app-user-menu__item app-user-menu__item_link"
        [routerLink]="profileRouterLink"
        (click)="linkClick.emit($event)"
        wMixpanel="Header navigation: Profile settings"
        [wKeyboardNavigationItem]="{origin: 'left'}"
      >
        <ng-container *ngTemplateOutlet="profileLinkContentTmpl" />
      </a>
    }

    <button
      class="app-user-menu__item app-user-menu__item_link"
      type="button"
      [disabled]="!!logoutDisabledReason"
      [attr.aria-label]="'Logout'"
      wLogoutButton
      wMixpanel="Header navigation: Logout"
      [wTooltip]="logoutDisabledReason"
      [wKeyboardNavigationItem]="{origin: 'left'}"
      [wTooltipArrow]="false"
      [wTooltipDisabled]="!logoutDisabledReason"
    >
      <w-svg-icon class="app-user-menu__icon" iconId="layout/logout" [size]="16" />
      <w-svg-icon class="app-user-menu__active-icon" iconId="layout/logout-active" [size]="16" />
      Log out
    </button>
  </div>
</div>

<ng-template #profileLinkContentTmpl>
  <w-svg-icon class="app-user-menu__icon" iconId="layout/profile" [size]="16" />
  <w-svg-icon class="app-user-menu__active-icon" iconId="layout/profile-active" [size]="16" />
  Profile settings
</ng-template>
