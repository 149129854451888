import {ChangeDetectionStrategy, Component, Input, ViewEncapsulation} from '@angular/core';

import {SpinnerComponent} from '@shared/components/spinner/spinner.component';

@Component({
  selector: 'w-splash-screen',
  templateUrl: './splash-screen.component.html',
  styleUrl: './splash-screen.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [SpinnerComponent],
})
export class SplashScreenComponent {
  @Input({required: true}) message: string;
}
