import {Directive} from '@angular/core';

@Directive({
  selector: 'ng-template[wAppSidebarLogo]',
  standalone: true,
})
export class AppSidebarLogoDirective {
  static ngTemplateContextGuard(dir: AppSidebarLogoDirective, ctx: any): ctx is {$implicit: boolean} {
    return true;
  }
}
