@if (isAuthenticated) {
  <div
    class="app-layout app-layout_{{ backgroundTheme }}"
    [class.app-layout_mobile]="isMobileLayout"
    [class.app-layout_with-copyright]="hasCopyright"
    wKeyboardNavigation
  >
    <w-app-header class="app-layout__header" [theme]="headerTheme" [canSwitchWorkspace]="canSwitchWorkspace">
      @if (isWhitelabel) {
        <ng-template wAppHeaderLogo><w-app-header-oem-logo /></ng-template>
      }
    </w-app-header>

    <w-app-sidebar
      class="app-layout__sidebar"
      [theme]="backgroundTheme"
      [items]="sidebarItems"
      [links]="sidebarLinks"
      [linksOffset]="sidebarLinksOffset"
    >
      @if (isWhitelabel) {
        <ng-template let-isExpanded wAppSidebarLogo>
          <w-app-sidebar-oem-logo [isExpanded]="isExpanded" (logoOffsetChange)="sidebarLinksOffset = $event" />
        </ng-template>
      }
    </w-app-sidebar>

    @if (isMobileLayout) {
      <w-app-mobile-sidebar class="app-layout__mobile-sidebar" [canSwitchWorkspace]="canSwitchWorkspace" />
    }

    <!--Using wKeyboardNavigation here to isolate arrow navigation within content from navigation in header/sidebars -->
    <div
      #contentElement
      class="app-layout__content"
      wKeyboardNavigation
      (wElementResize)="updateOuterContentSize($event, contentElement)"
    >
      <div
        #innerContentElement
        class="app-layout__inner-content"
        [wElementResizeDisabled]="!isEmbedded"
        (wElementResize)="updateInnerContentSize(innerContentElement, contentElement)"
      >
        <w-page-banners class="app-layout__banners" wStickyArea />

        <router-outlet />
      </div>
    </div>

    <div class="app-layout__footer">
      @if (hasCopyright) {
        <w-widget-copyright class="app-layout__widget-copyright" />
      }
    </div>

    @if (hasMobileOverlay$ | async) {
      <div @fadeInOut class="app-layout__overlay" (click)="handleOverlayClick()"></div>
    }
  </div>
} @else {
  <w-guest-layout />
}

<ng-container *ngComponentOutlet="oemCustomizationComponent$ | async" />
