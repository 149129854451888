import {ChangeDetectionStrategy, Component, Input, ViewEncapsulation} from '@angular/core';

import {KeyboardNavigationItemDirective} from '@shared/modules/keyboard-navigation/keyboard-navigation-item.directive';
import {SvgIconComponent} from '@shared/components/svg-icon/svg-icon.component';

@Component({
  selector: 'w-app-mobile-sidebar-button',
  templateUrl: './app-mobile-sidebar-button.component.html',
  styleUrl: './app-mobile-sidebar-button.component.scss',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [KeyboardNavigationItemDirective, SvgIconComponent],
})
export class AppMobileSidebarButtonComponent {
  @Input({required: true}) type: 'forward' | 'back';
  @Input({required: true}) ariaLabel: string;

  @Input() disabled = false;
}
