import {noop} from 'lodash';
import {debounceTime} from 'rxjs/operators';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
  inject,
} from '@angular/core';
import {ControlValueAccessor, FormControl, ReactiveFormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';

import {SvgIconComponent} from '../../../components/svg-icon/svg-icon.component';
import {FocusWhenDirective} from '../../../directives/focus-when.directive';
import {DisableCompositionBufferDirective} from '../../../directives/disable-composition-buffer.directive';
import {KeyboardNavigationItemDirective} from '../../keyboard-navigation/keyboard-navigation-item.directive';
import {subscriptions} from '../../../services/subscriptions';
import {WSimpleChanges} from '../../../types/angular';
import {IconButtonComponent} from '../../../components/icon-button/icon-button.component';

const DEFAULT_INPUT_SEARCH_DEBOUNCE = 600;

export type SearchFieldSize = 'default' | 'small' | 'compact';

@Component({
  selector: 'w-search-field',
  templateUrl: './search-field.component.html',
  styleUrls: ['./search-field.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    SvgIconComponent,
    ReactiveFormsModule,
    FocusWhenDirective,
    DisableCompositionBufferDirective,
    KeyboardNavigationItemDirective,
    IconButtonComponent,
  ],
})
export class SearchFieldComponent<TValue extends string | null | undefined = string>
  implements OnInit, OnChanges, ControlValueAccessor
{
  @Input({required: true}) value: TValue;
  @Input() size: SearchFieldSize = 'default';
  @Input() placeholder = '';
  @Input() inputDebounce: number = DEFAULT_INPUT_SEARCH_DEBOUNCE;
  @Input() inputName = 'search-input';
  @Input() emitOnBlur = true;
  @Input() autocomplete = true;
  @Input() autofocus?: any;
  @Input() autofocusPreventScroll = false;
  @Input() disabled?: boolean;

  @Output() valueChange = new EventEmitter<TValue>();
  @Output() onSearch = new EventEmitter<TValue>();

  @ViewChild('inputElement', {static: true}) inputElement: ElementRef<HTMLInputElement>;

  focused = false;
  query = new FormControl();

  protected cd = inject(ChangeDetectorRef);

  protected onChange = noop;
  protected emittedSearchValue: TValue;
  protected subs = subscriptions();

  ngOnInit() {
    this.subs.add(
      this.query.valueChanges.pipe(debounceTime(TEST ? 0 : this.inputDebounce)).subscribe(() => {
        this.handleValueChange();
        this.cd.markForCheck();
      }),
    );

    this.query.setValue(this.value);
  }

  ngOnChanges(changes: WSimpleChanges<SearchFieldComponent<TValue>>) {
    if (changes.disabled) {
      changes.disabled.currentValue ? this.query.disable() : this.query.enable();
    }

    if (changes.value && !changes.value.isFirstChange()) {
      this.query.setValue(this.value, {
        emitViewToModelChange: false,
      });
    }
  }

  handleValueChange(emitSearchEvent?: boolean) {
    if (this.value !== this.query.value) {
      this.value = this.query.value;
      this.onChange(this.value);
      this.valueChange.emit(this.value);
    }

    if (emitSearchEvent && this.value !== this.emittedSearchValue) {
      this.emittedSearchValue = this.value;
      this.onSearch.emit(this.value);
    }
  }

  handleFocus() {
    this.focused = true;
  }

  handleBlur() {
    this.focused = false;
    this.handleValueChange(this.emitOnBlur);
  }

  handleSubmit(event: Event) {
    event.preventDefault();
    this.handleValueChange(true);
  }

  clear() {
    this.query.setValue('');
    this.handleValueChange(true);
  }

  focus() {
    this.inputElement.nativeElement.focus();
    this.cd.markForCheck();
  }

  writeValue(value: any) {
    this.value = value;
    this.query.setValue(value, {
      emitEvent: false,
      emitModelToViewChange: true,
      emitViewToModelChange: false,
    });
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched() {
    // We're not interested in "touched" state
  }
}
