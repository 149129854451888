import {ChangeDetectionStrategy, Component, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {animate, group, query, state, style, transition, trigger} from '@angular/animations';

import {KeyboardNavigationDirective} from '@shared/modules/keyboard-navigation/keyboard-navigation.directive';
import {GlobalCaptureEventListeners} from '@shared/services/global-capture-event-listeners.service';
import {EASY_EASE} from '@shared/constants/animations';

import {SharedModule} from '../../shared.module';
import {AbstractAppContextSwitcher} from '../app-context-switcher/abstract-app-context-switcher';
import {AppUserMenuComponent} from '../app-user-menu/app-user-menu.component';
import {AppMenuItemIconDirective} from '../app-menu-item/app-menu-item-icon.directive';
import {UserAvatarModule} from '../../modules/user-avatar/user-avatar.module';
import {AppMenuComponent} from '../app-menu/app-menu.component';
import {AppMobileSidebarButtonComponent} from '../app-mobile-sidebar-button/app-mobile-sidebar-button.component';
import {AppMenuOptionGroup, AppMenuOptions} from '../app-menu/app-menu.types';
import {AppContextSwitcherIconComponent} from '../app-context-switcher-icon/app-context-switcher-icon.component';

import {AppMobileSidebarService} from './app-mobile-sidebar.service';

const animationFunction = `200ms ${EASY_EASE}`;

type MobileSidebarView = 'main' | 'workspace' | 'environment' | 'product';

@Component({
  selector: 'w-app-mobile-sidebar',
  templateUrl: './app-mobile-sidebar.component.html',
  styleUrl: './app-mobile-sidebar.component.scss',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  animations: [
    trigger('slideInOut', [
      state('collapsed', style({transform: 'translateX(100%)', opacity: 0})),
      state('expanded', style({transform: '', opacity: 1})),
      transition('collapsed <=> expanded', animate(animationFunction)),
    ]),
    trigger('contentSwitch', [
      transition('main => *', [
        group([
          query(':leave', [animate(animationFunction, style({transform: 'translateX(-100%)'}))]),
          query(':enter', [animate(animationFunction, style({transform: 'translateX(-100%)'}))]),
        ]),
      ]),
      transition('* => main', [
        group([
          query(':leave', [style({transform: 'translateX(-100%)'}), animate(animationFunction)], {optional: true}),
          query(':enter', [style({transform: 'translateX(-100%)'}), animate(animationFunction)]),
        ]),
      ]),
    ]),
  ],
  imports: [
    SharedModule,
    UserAvatarModule,
    AppMenuComponent,
    AppUserMenuComponent,
    AppMenuItemIconDirective,
    AppMobileSidebarButtonComponent,
    KeyboardNavigationDirective,
    AppContextSwitcherIconComponent,
  ],
})
export class AppMobileSidebarComponent extends AbstractAppContextSwitcher implements OnInit {
  @ViewChild(KeyboardNavigationDirective, {static: true}) keyboardNavigation: KeyboardNavigationDirective;

  isOpened = false;
  view: MobileSidebarView = 'main';

  constructor(
    private appMobileSidebarService: AppMobileSidebarService,
    private globalEvents: GlobalCaptureEventListeners,
  ) {
    super();
  }

  override ngOnInit() {
    super.ngOnInit();

    this.subs.add(
      this.appMobileSidebarService.isSidebarOpened$.subscribe(isOpened => {
        this.isOpened = isOpened;
        this.view = 'main';
        this.cd.markForCheck();

        if (isOpened) {
          this.globalEvents.add('keydown.esc', this.handleEscapeKeydown);
        } else {
          this.globalEvents.remove('keydown.esc', this.handleEscapeKeydown);
        }
      }),
    );
  }

  setView(view: MobileSidebarView) {
    this.view = view;
  }

  goBack() {
    this.setView('main');
  }

  closeSidebar() {
    this.appMobileSidebarService.closeSidebar();
  }

  hasMultipleOptions(options: AppMenuOptions<unknown, object>): boolean {
    return options.length > 1 || (options[0] as AppMenuOptionGroup<unknown, object>)?.options?.length > 1;
  }

  handleAnimationEnd() {
    if (this.isOpened) {
      // Move focus inside the sidebar once it's open and animation is finished
      this.keyboardNavigation.service.autofocus();
    }
  }

  private handleEscapeKeydown = () => {
    this.closeSidebar();
  };
}
