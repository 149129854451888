import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {Observable, combineLatestWith} from 'rxjs';
import {map} from 'rxjs/operators';
import {trigger} from '@angular/animations';

import {ComponentClass} from '@shared/types/angular';
import {LayoutService} from '@shared/services/layout.service';
import {ElementDimension} from '@shared/directives/element-resize.directive';
import {subscriptions} from '@shared/services/subscriptions';

import {AuthUser} from '../../services/auth-user';
import {OemThemeCustomizationService} from '../oem-theme-customization/oem-theme-customization.service';
import {OemService} from '../../modules/oem/oem.service';
import {Environment} from '../../services/environment';
import {AccountEnvironmentTheme} from '../../services/account-environments.types';
import {AppSidebarItem, AppSidebarLink} from '../app-sidebar/app-sidebar.types';
import {AppSidebarService} from '../app-sidebar/app-sidebar.service';
import {AppMobileSidebarService} from '../app-mobile-sidebar/app-mobile-sidebar.service';
import {opacityFadeInOut} from '../../animations/opacity-fade-in-out.animation';
import {AppContextSwitcherService} from '../app-context-switcher/app-context-switcher.service';
import {AppLogoutStateService} from '../app-user-menu/app-logout-state.service';

const TEST_ENV_THEMES: AccountEnvironmentTheme[] = ['test1', 'test2', 'test3', 'sandbox'];

@Component({
  selector: 'w-app-layout',
  templateUrl: './app-layout.component.html',
  styleUrl: './app-layout.component.scss',
  encapsulation: ViewEncapsulation.None,
  animations: [trigger('fadeInOut', opacityFadeInOut())],
})
export class AppLayoutComponent implements OnInit {
  readonly oemCustomizationComponent$: Observable<ComponentClass | null>;
  readonly hasMobileOverlay$: Observable<boolean>;

  isMobileLayout = false;
  sidebarItems: AppSidebarItem[];
  sidebarLinks: AppSidebarLink[];
  sidebarLinksOffset = 0;

  private subs = subscriptions();

  constructor(
    private authUser: AuthUser,
    private oemService: OemService,
    private environment: Environment,
    private layoutService: LayoutService,
    private appContextSwitcherService: AppContextSwitcherService,
    private appMobileSidebarService: AppMobileSidebarService,
    private appSidebarService: AppSidebarService,
    private appLogoutState: AppLogoutStateService,
    private oemThemeCustomizationService: OemThemeCustomizationService,
  ) {
    this.oemCustomizationComponent$ = this.oemThemeCustomizationService.customizationComponent$;
    this.hasMobileOverlay$ = this.layoutService.isMobileLayout$.pipe(
      combineLatestWith(this.appSidebarService.isSidebarOpened$, this.appMobileSidebarService.isSidebarOpened$),
      map(
        ([isMobileLayout, isSidebarOpened, isMobileSidebarOpened]) =>
          isMobileLayout && (isSidebarOpened || isMobileSidebarOpened),
      ),
    );
  }

  ngOnInit() {
    this.sidebarItems = this.appSidebarService.buildSidebar();
    this.sidebarLinks = this.initOemSidebarLinks();

    this.subs.add(
      this.layoutService.isMobileLayout$.subscribe(isMobileLayout => {
        this.isMobileLayout = isMobileLayout;
      }),
      this.oemThemeCustomizationService.isCustomizationMode$.subscribe(isCustomizationMode => {
        if (isCustomizationMode) {
          this.appLogoutState.disableLogout("You can't logout while customizing your theme");
        } else {
          this.appLogoutState.enableLogout();
        }
      }),
    );
  }

  get headerTheme(): 'dev' | 'test' | 'prod' | 'non-env' {
    if (this.authUser.oem_user && !this.appContextSwitcherService.hasEnvironments) {
      return 'non-env';
    }

    const {theme} = this.appContextSwitcherService.currentEnvironment;

    if (TEST_ENV_THEMES.includes(theme)) {
      return 'test';
    }

    if (theme === 'prod') {
      return 'prod';
    }

    return 'dev';
  }

  get backgroundTheme(): 'dev' | 'test' | 'prod' | 'non-env' {
    return this.authUser.oem_user ? 'non-env' : this.headerTheme;
  }

  get isAuthenticated(): boolean {
    return this.authUser.authenticated;
  }

  get isWhitelabel(): boolean {
    return this.oemService.isWhitelabel;
  }

  get isEmbedded(): boolean {
    return this.oemService.embedded;
  }

  get canSwitchWorkspace(): boolean {
    return !(this.oemService.themeEnabled && this.oemService.workspaceSwitchingDisabled);
  }

  get hasCopyright(): boolean {
    return this.environment.isWidgetMode && this.oemService.footerBrandingVisible;
  }

  updateOuterContentSize({height, width}: ElementDimension, contentElement: HTMLElement) {
    contentElement.style.setProperty('--app-layout-content-height', `${height}px`);
    contentElement.style.setProperty('--app-layout-content-width', `${width}px`);
  }

  updateInnerContentSize(innerContentElement: HTMLElement, contentElement: HTMLElement) {
    const outerHeightWithoutContent = document.body.offsetHeight - contentElement.offsetHeight;

    this.oemService.handleHeightChange(innerContentElement.offsetHeight + outerHeightWithoutContent);
  }

  handleOverlayClick() {
    this.appSidebarService.closeSidebar();
    this.appMobileSidebarService.closeSidebar();
  }

  private initOemSidebarLinks(): AppSidebarLink[] {
    const links: AppSidebarLink[] = [];

    if (!this.oemService.themeEnabled) {
      return links;
    }

    if (this.oemService.footerLinks?.privacy_url) {
      links.push({
        name: 'Privacy',
        href: this.oemService.footerLinks.privacy_url,
      });
    }

    if (this.oemService.footerLinks?.terms_url) {
      links.push({
        name: 'Terms',
        href: this.oemService.footerLinks.terms_url,
      });
    }

    if (this.oemService.footerLinks?.support) {
      links.push({
        name: 'Support',
        href: this.oemService.footerLinks.support,
      });
    }

    return links;
  }
}
