import _ from 'lodash';
import {Observable, Subject} from 'rxjs';
import {Injectable} from '@angular/core';
import dateIcon from '@icons/field-type/date.svg';
import dateArrayIcon from '@icons/field-type/date-array.svg';
import dateTimeIcon from '@icons/field-type/date-time.svg';
import dateTimeArrayIcon from '@icons/field-type/date-time-array.svg';
import urlIcon from '@icons/field-type/url.svg';
import numberIcon from '@icons/field-type/number.svg';
import numberArrayIcon from '@icons/field-type/number-array.svg';
import phoneIcon from '@icons/field-type/phone.svg';
import emailIcon from '@icons/field-type/email.svg';
import textIcon from '@icons/field-type/text.svg';
import textArrayIcon from '@icons/field-type/text-array.svg';
import arrayIcon from '@icons/field-type/array.svg';
import objectIcon from '@icons/field-type/object.svg';
import booleanIcon from '@icons/field-type/boolean.svg';
import booleanArrayIcon from '@icons/field-type/boolean-array.svg';
import integerIcon from '@icons/field-type/integer.svg';
import integerArrayIcon from '@icons/field-type/integer-array.svg';
import cronIcon from '@icons/field-type/cron.svg';
import timeIcon from '@icons/field-type/time.svg';
import {distinctUntilChanged, filter, map, startWith, switchMap} from 'rxjs/operators';
import {v4 as uuid} from 'uuid';

import {Log} from '@shared/services/log';
import {RequiredProps} from '@shared/types/lib';
import {labelize} from '@shared/utils/labelize';
import {lowerFirst} from '@shared/utils/lower-first';

import {ExpressionParser} from '../../services/expression-parser';
import {ExpressionFunction} from '../../services/expression-parser/types';
import {
  AbstractArrayField,
  AbstractObjectField,
  AbstractStaticListField,
  AbstractTextField,
  ArrayOutputField,
  BooleanSelectField,
  CodeField,
  ComplexField,
  Condition,
  ControlType,
  FieldValue,
  FieldValueType,
  FieldWithOptions,
  FieldWithProperties,
  FieldsInput,
  KeyValueField,
  ListField,
  MultiSelectField,
  ObjectOutputField,
  OutputSchemaField,
  PasswordField,
  Picklist,
  PlainTextField,
  PrimitiveListField,
  RecipeStep,
  SchemaBuilderField,
  SchemaField,
  SelectField,
  SelectFieldWithOptions,
  SelectLikeField,
  StaticListField,
  SubdomainField,
  TextField,
  ToggleField,
  TreeSelectField,
} from '../../types';
import {RecipeParametersStep} from '../recipe-parameters/recipe-parameters-step';
import {TemplateCompiler} from '../../services/template-compiler';
import {helpers} from '../../components/html-template/helpers';
import {AppDictionary} from '../../services/app-dictionary/app-dictionary';
import {AuthUser} from '../../services/auth-user';

import {SecretsScanType} from './secrets-scanner/secrets-scanner.service';
import {
  BulkFieldEvent,
  FieldContext,
  FieldOptionsChange,
  FieldParentChange,
  FieldValueChange,
  FormFieldWidgetType,
  ListFieldItemTemplateData,
  ListItemFieldClearedEvent,
} from './form-field.types';

const TEXT_INPUT_CONTROL_TYPES = new Set<ControlType>([
  'text',
  'text-area',
  'date',
  'date_time',
  'email',
  'phone',
  'number',
  'integer',
  'url',
  'list',
  'cron',
  'time',
]);

const ICON_TYPES = new Map<FieldValueType | ControlType, SvgIcon>([
  ['date', dateIcon],
  ['date_time', dateTimeIcon],
  ['url', urlIcon],
  ['number', numberIcon],
  ['phone', phoneIcon],
  ['email', emailIcon],
  ['text', textIcon],
  ['array', arrayIcon],
  ['object', objectIcon],
  ['boolean', booleanIcon],
  ['integer', integerIcon],
  ['cron', cronIcon],
  ['time', timeIcon],
]);

const ARRAY_ICON_TYPES = new Map<FieldValueType | ControlType, SvgIcon>([
  ['date', dateArrayIcon],
  ['date_time', dateTimeArrayIcon],
  ['number', numberArrayIcon],
  ['text', textArrayIcon],
  ['boolean', booleanArrayIcon],
  ['integer', integerArrayIcon],
]);

const COMPILED_EXPRESSION_KEYS: {[key in ExpressionType]: symbol} = {
  ngIf: Symbol('IF_EXPRESSION_FUNCTION'),
  optionalExpression: Symbol('OPTIONAL_EXPRESSION_FUNCTION'),
};

export const VALID_FIELD_NAME = /^[a-z]\w*$/i;

export const ARRAY_SOURCE_FIELD_NAME = '____source';

export const DEFAULT_LIST_FIELD_MODE = 'dynamic';

export const DEFAULT_LIST_ITEM_LABEL = 'Item';

const fieldExpressionParser = new ExpressionParser({
  allowedDataProps: ['line', 'input', 'parentInput', 'authUser'],
});

export interface InputOptions {
  ignoreParent?: boolean;
  ignoreDefaults?: boolean;
}

export interface ExpressionContext {
  input?: FieldsInput;
  step?: RecipeStep;
  fieldContext?: FieldContext;
}

export type FieldsInputWithContext = RequiredProps<ExpressionContext, 'input'>;

export type FieldQualifiedPathElement = string | number;

/**
 * Contains full path to the field, including all parent field names
 * e.g. ['items', 0, 'name']
 */
export type FieldQualifiedPath = FieldQualifiedPathElement[];

export interface SetFieldParentResult {
  parentChanged: boolean;
  field: SchemaField;
}

interface InternalExpressionContext {
  authUser: AuthUser;
  input: FieldsInput;
  line?: RecipeStep | FieldContext;
  parentInput: FieldsInput;
}

type ExpressionType = Extract<keyof SchemaField, 'ngIf' | 'optionalExpression'>;

@Injectable({
  providedIn: 'root',
})
export class FieldHelper {
  /*
   * Emits qualified field name if only it's value changed.
   * Emits event without payload if values for many fields have changed.
   */
  fieldValueChanged$: Observable<FieldValueChange | void>;
  fieldParentChanged$: Observable<FieldParentChange>;
  fieldOptionsChanged$: Observable<FieldOptionsChange>;
  listItemFieldCleared$: Observable<ListItemFieldClearedEvent>;
  showAllFields$: Observable<void>;

  lastFieldValueChange: FieldValueChange | null = null;

  private _fieldValueChanged = new Subject<FieldValueChange | void>();
  private _fieldParentChanged = new Subject<FieldParentChange>();
  private _fieldOptionsChanged = new Subject<FieldOptionsChange>();
  private _listItemFieldCleared = new Subject<ListItemFieldClearedEvent>();
  private _showAllFields = new Subject<void>();

  constructor(
    private log: Log,
    private appDictionary: AppDictionary,
    private authUser: AuthUser,
  ) {
    this.fieldValueChanged$ = this._fieldValueChanged.asObservable();
    this.fieldParentChanged$ = this._fieldParentChanged.asObservable();
    this.fieldOptionsChanged$ = this._fieldOptionsChanged.asObservable();
    this.listItemFieldCleared$ = this._listItemFieldCleared.asObservable();
    this.showAllFields$ = this._showAllFields.asObservable();
  }

  /* Getters */

  getDataTypeIcon(field: SchemaField): string {
    let type: SchemaField['type'] | ControlType;
    let array: boolean;

    if (this.isPrimitiveListField(field)) {
      type = field.of;
      array = true;
    } else {
      type = field.control_type === 'date' ? 'date' : field.type;
      array = false;
    }

    return this.getIconType(type, array);
  }

  getFieldTypeIcon(field: SchemaField): string {
    let type: SchemaField['type'] | ControlType;
    let array: boolean;

    if (this.isPrimitiveListField(field)) {
      type = field.of;
      array = true;
    } else {
      type = field.control_type && field.control_type !== 'select' ? field.control_type : field.type;
      array = false;
    }

    return this.getIconType(type, array);
  }

  getIconType(type: SchemaField['type'] | ControlType, array = false): string {
    const icons = array ? ARRAY_ICON_TYPES : ICON_TYPES;

    if (type === 'list' || type === 'key_value') {
      type = 'array';
    } else if (!type || !icons.has(type)) {
      type = 'text';
    }

    return icons.get(type)!.id;
  }

  getQualifiedPath(field: SchemaField): FieldQualifiedPath {
    const parentPath = field.parent ?? [];

    if (field.synthetic) {
      return parentPath;
    }

    return [...parentPath, field.name].filter(part => typeof part === 'number' || Boolean(part));
  }

  getQualifiedPathAsString(field: SchemaField): string {
    const path = this.getQualifiedPath(field);

    return this.stringifyQualifiedPath(path);
  }

  getQualifiedPathAsObservable(schemaField: SchemaField | Observable<SchemaField>): Observable<FieldQualifiedPath> {
    if (schemaField instanceof Observable) {
      return schemaField.pipe(switchMap(field => this.getQualifiedPathAsObservable(field)));
    }

    return this.fieldParentChanged$.pipe(
      startWith(this.buildBulkFieldEvent([schemaField])),
      filter(bulkMap => bulkMap.has(schemaField)),
      map(bulkMap => bulkMap.get(schemaField)!.qualifiedPath),
      distinctUntilChanged((path1, path2) => this.pathsAreEqual(path1, path2)),
    );
  }

  getFieldValueAsObservable(pathGetter: () => FieldQualifiedPath): Observable<FieldValueChange | void> {
    return this.fieldValueChanged$.pipe(
      filter(change => !change || this.pathsAreEqual(change.qualifiedPath, pathGetter())),
    );
  }

  getQualifiedPathWithoutPrefix(field: SchemaField): FieldQualifiedPath {
    return this.removeInternalPrefix(this.getQualifiedPath(field));
  }

  getQualifiedPathWithPrefix(field: SchemaField, step: RecipeStep | RecipeParametersStep): FieldQualifiedPath {
    const fieldPath = this.getQualifiedPath(field);

    return this.addStepKeywordPrefix(fieldPath, step);
  }

  getPicklistPath(field: SchemaField): string {
    return this.getPicklistPathFromQualifiedPath(this.getQualifiedPath(field));
  }

  getPicklistPathFromQualifiedPath(path: FieldQualifiedPath): string {
    return this.stringifyQualifiedPath(this.removeIndexesFromQualifiedPath(path));
  }

  getSecretScanType(field: SchemaField): SecretsScanType {
    const textAreaControlTypes: ControlType[] = ['text-area', 'plain-text-area'];

    return textAreaControlTypes.includes(field.control_type) ? 'content' : 'all';
  }

  getFieldIdSelector(fieldPath: FieldQualifiedPath): string {
    const fieldPathString = this.stringifyQualifiedPath(fieldPath);

    return `[data-field-id^='${fieldPathString}']`;
  }

  getKeyValueFieldsSchema(field: KeyValueField): KeyValueField['properties'] {
    const childFieldsCount = field.properties.length;

    if (childFieldsCount !== 2) {
      this.log.error(
        `Schema for "${field.name}" key-value field must have exactly 2 child fields, but it has ${childFieldsCount}.`,
      );
    }

    const [keyFieldConfig, valueFieldConfig] = field.properties;
    const keyLabel = this.getFieldLabel(keyFieldConfig);
    const valueLabel = this.getFieldLabel(valueFieldConfig);

    const keyField: TextField = {
      ...keyFieldConfig,
      control_type: 'text',
      label: keyLabel,
      placeholder: keyFieldConfig.placeholder || `Enter ${lowerFirst(keyLabel)}`,
      disable_formula: keyFieldConfig.disable_formula || field.disable_formula,
      optional: false,
      no_type_icon: true,
      no_field_flag: true,
    };

    const valueField = {
      ...valueFieldConfig,
      label: valueLabel,
      placeholder: valueFieldConfig.placeholder || `Enter ${lowerFirst(valueLabel)}`,
      optional: false,
      no_field_flag: true,
    };

    if (valueField.control_type === 'password') {
      return [
        keyField,
        {
          ...valueField,
          control_type: 'password',
        },
      ];
    }

    return [
      keyField,
      {
        ...valueField,
        control_type: 'text',
        disable_formula: valueField.disable_formula || field.disable_formula,
        no_type_icon: true,
      },
    ];
  }

  getFieldLabel(field: SchemaField): string {
    return field.label || labelize(field.name);
  }

  getSchemaBuilderData(field: SchemaBuilderField): AbstractObjectField | undefined {
    return field.properties[1];
  }

  getSchemaBuilderSchema(field: SchemaBuilderField): SchemaField[] {
    return this.getSchemaBuilderData(field)?.properties ?? [];
  }

  getRawValue(field: SchemaField, input: FieldsInput, opts: InputOptions = {}): FieldValue {
    const path = opts.ignoreParent ? [field.name] : this.getQualifiedPath(field);
    // Handle cases to avoid error on paths which uses prototype prop names (eg toString, hasOwnProperty`)
    const value = _.has(input, path) ? _.get(input, path) : undefined;

    return opts.ignoreDefaults && value === field.default ? null : value;
  }

  getValue(field: SchemaField, input: FieldsInput, opts?: InputOptions): string {
    const value = this.getRawValue(field, input, opts);

    // Handle objects separately to avoid error on objects which override prototype props (eg `{toString: 0}`)
    return _.isPlainObject(value) ? '[object Object]' : _.toString(value);
  }

  /* Type guards and conditions */

  isValidValue(field: SchemaField, value: FieldValue): boolean {
    if (value === undefined || value === null) {
      // Absent value is always valid
      return true;
    }

    if (field.control_type === 'multiselect') {
      return _.isString(value) || _.isArray(value);
    }

    switch (field.type) {
      case 'object':
        return _.isPlainObject(value);

      case 'array':
        if (this.isPrimitiveListField(field)) {
          return typeof value === 'string';
        }

        if ((field as ListField).list_mode_toggle !== false) {
          return _.isArray(value) || _.isPlainObject(value);
        }

        if ((field as ListField).list_mode === 'static') {
          return _.isArray(value);
        } else {
          return _.isPlainObject(value);
        }

      default:
        return !_.isPlainObject(value) && !_.isArray(value);
    }
  }

  isVisible(field: SchemaField, expressionContext: ExpressionContext): boolean {
    if (!field.ngIf) {
      return true;
    }

    return Boolean(this.evalExpression(field, 'ngIf', expressionContext));
  }

  isOptional(field: SchemaField, expressionContext: ExpressionContext): boolean {
    if (!field.optionalExpression) {
      return Boolean(field.optional);
    }

    return Boolean(this.evalExpression(field, 'optionalExpression', expressionContext));
  }

  isMapped(field: SchemaField, input: FieldsInput, opts: InputOptions = {}): boolean {
    const value = this.getRawValue(field, input, opts);

    if (!this.isEmptyValue(value)) {
      return true;
    }

    const toggleFieldValue = this.isToggleField(field) ? this.getRawValue(field.toggle_field, input, opts) : null;

    return !(this.isEmptyValue(toggleFieldValue) || (opts.ignoreDefaults && toggleFieldValue === field.default));
  }

  isEmptyValue(value: FieldValue): boolean {
    if (_.isArray(value) || _.isPlainObject(value)) {
      return _.every(value, val => this.isEmptyValue(val));
    }

    return value === null || value === undefined || value === '';
  }

  isSchemaBuilderField(field: SchemaField): field is SchemaBuilderField {
    return field.control_type === 'form-schema-builder';
  }

  isPlainTextField(field: SchemaField): field is PlainTextField {
    return field.control_type === 'plain-text';
  }

  isSubdomainField(field: SchemaField): field is SubdomainField {
    return field.control_type === 'subdomain';
  }

  isTextField(field: SchemaField): field is TextField {
    return TEXT_INPUT_CONTROL_TYPES.has(field.control_type);
  }

  isCodeField(field: SchemaField): field is CodeField {
    return field.control_type === 'code';
  }

  isToggleField(field: SchemaField): field is ToggleField {
    return _.isPlainObject((field as ToggleField).toggle_field);
  }

  isSelectField(field: SchemaField): field is SelectField {
    return field.control_type === 'select';
  }

  isMultiSelectField(field: SchemaField): field is MultiSelectField {
    return field.control_type === 'multiselect';
  }

  isTreeSelectField(field: SchemaField): field is TreeSelectField {
    return field.control_type === 'tree';
  }

  isSelectLikeField(field: SchemaField): field is SelectLikeField {
    return this.isSelectField(field) || this.isMultiSelectField(field) || this.isTreeSelectField(field);
  }

  isPasswordField(field: SchemaField): field is PasswordField {
    return field.control_type === 'password';
  }

  isArrayLikeField<TField extends SchemaField>(field: TField): field is AbstractArrayField<TField> {
    return field.type === 'array';
  }

  isObjectLikeField<TField extends SchemaField>(field: TField): field is AbstractObjectField<TField> {
    return field.type === 'object';
  }

  canContainChildFields<TField extends SchemaField>(
    field: TField,
  ): field is AbstractArrayField<TField> | AbstractObjectField<TField> {
    return (this.isArrayLikeField(field) && (field.of === 'object' || !field.of)) || this.isObjectLikeField(field);
  }

  isTextLikeField(field: SchemaField): field is AbstractTextField {
    return this.isTextField(field) || this.isCodeField(field) || this.isPrimitiveListField(field);
  }

  isListField(field: SchemaField): field is ListField {
    return this.isArrayLikeField(field) && !field.control_type && this.hasChildren(field);
  }

  isComplexField(field: SchemaField): field is ComplexField {
    return field.type === 'object' && !field.control_type && this.hasChildren(field);
  }

  isStaticListField(field: SchemaField): field is StaticListField {
    return this.isListField(field) && field.list_mode === 'static' && field.list_mode_toggle === false;
  }

  isListFieldInStaticMode(field: SchemaField, fieldValue: FieldValue): field is ListField {
    return (
      this.isListField(field) &&
      (field.list_mode === 'static' || (field.list_mode_toggle !== false && _.isArray(fieldValue)))
    );
  }

  isKeyValueField(field: SchemaField): field is KeyValueField {
    return field.control_type === 'key_value';
  }

  isPrimitiveListField(field: SchemaField): field is PrimitiveListField {
    return Boolean(this.isArrayLikeField(field) && field.of && field.of !== 'object' && !this.hasChildren(field));
  }

  isBooleanSelectField(field: SchemaField): field is BooleanSelectField {
    return field.control_type === 'checkbox';
  }

  isFieldWithProperties(field: SchemaField): field is FieldWithProperties {
    return Boolean((field as FieldWithProperties).properties);
  }

  isFieldWithOptions(field: SelectLikeField): field is FieldWithOptions {
    return Array.isArray((field as FieldWithOptions).options);
  }

  supportsPills(field: SchemaField | undefined, inRecipeContext: boolean): boolean {
    if (!field || !inRecipeContext) {
      return false;
    }

    if (this.isPrimitiveListField(field)) {
      return true;
    }

    const isTextFieldWithPillsSupport =
      this.isTextField(field) && field.support_pills !== false && !field.disable_formula;
    const isCodeFieldWithPillsSupport = this.isCodeField(field) && Boolean(field.support_pills);

    return isTextFieldWithPillsSupport || isCodeFieldWithPillsSupport;
  }

  hasChildren(field: OutputSchemaField): field is ArrayOutputField | ObjectOutputField;

  hasChildren(field: SchemaField): field is FieldWithProperties;

  hasChildren(field: SchemaField | OutputSchemaField): boolean {
    return Boolean((field as FieldWithProperties | ArrayOutputField | ObjectOutputField).properties?.length);
  }

  hasDynamicItemLabel(field: StaticListField): boolean {
    return Boolean(field.item_label && TemplateCompiler.isTemplate(field.item_label));
  }

  conditionHasRhs(operand: Condition['operand']): boolean {
    const ifCondition = this.appDictionary.getIfCondition(operand) || {};

    return !('hide_rhs' in ifCondition);
  }

  generateConditionId(): Condition['uuid'] {
    return uuid();
  }

  createCondition(): Condition {
    return {operand: '', lhs: '', rhs: '', uuid: this.generateConditionId()};
  }

  /* State modification methods */

  setValue(field: SchemaField, input: FieldsInput, value: any, opts?: InputOptions) {
    const qualifiedPath = this.getQualifiedPath(field);
    const path = opts?.ignoreParent ? field.name : qualifiedPath;
    const change: FieldValueChange = {qualifiedPath, field, input, value};

    _.set(input, path, value);
    this._fieldValueChanged.next(change);
    this.lastFieldValueChange = change;
  }

  unsetValue(field: SchemaField, input: FieldsInput, opts?: InputOptions) {
    const qualifiedPath = this.getQualifiedPath(field);
    const path = opts?.ignoreParent ? field.name : qualifiedPath;
    const change: FieldValueChange = {qualifiedPath, field, input, value: undefined};

    _.unset(input, path);
    this._fieldValueChanged.next();
    this.lastFieldValueChange = change;
  }

  unsetListItemField(field: AbstractStaticListField, path: FieldQualifiedPath) {
    this._listItemFieldCleared.next({field, path});
  }

  setOptions(field: FieldWithOptions, options: FieldWithOptions['options']) {
    field.options = options;
    this._fieldOptionsChanged.next({field, options, qualifiedPath: this.getQualifiedPath(field)});
  }

  setParent(field: SchemaField, parent: SchemaField['parent'], notify = true): SetFieldParentResult {
    if (this.pathsAreEqual(field.parent, parent)) {
      return {parentChanged: false, field};
    }

    field.parent = parent;

    if (notify) {
      this._fieldParentChanged.next(this.buildBulkFieldEvent([field]));
    }

    return {parentChanged: true, field};
  }

  defineParent(
    field: SchemaField,
    parentField: SchemaField,
    indexWithinParent?: number,
    notify = true,
  ): SetFieldParentResult {
    const parentPath = this.getQualifiedPath(parentField);

    if (typeof indexWithinParent === 'number') {
      parentPath.push(indexWithinParent);
    }

    return this.setParent(field, parentPath, notify);
  }

  /**
   * For cases when parents are defined for lots of fields simultaneously, it is preferred to notify about the changes in bulk
   * Otherwise, due to potential high number of subscribers, _fieldParentChanged.next might create significant lag
   * For example, we have a case in production with Workbot for Slack connector which renders around 100 fields inside each block, which we may have 20 or more
   * This creates 2k+ subscriptions for parent change via getQualifiedPathAsObservable
   * Combining that with frequency of _fieldParentChanged.next calls, we might encounter a situation when the pipeline is called 2000 x 2000 = 4mil times,
   * which causes major lag, even though the event is filtered out with rxjs filter operator
   * Notifying in bulk reduces the number of iterations and thus optimizes recipe config initial render times
   */
  notifyBulkParentsChange(fields: SchemaField[]) {
    this._fieldParentChanged.next(this.buildBulkFieldEvent(fields));
  }

  showAllFields() {
    this._showAllFields.next();
  }

  refreshValues() {
    this._fieldValueChanged.next();
  }

  /* General helper methods */

  traverseInput(
    obj: FieldValue,
    callback: (fullPath: FieldQualifiedPath, value: FieldValue) => void,
    options: {parentKey: FieldQualifiedPath} = {parentKey: []},
  ) {
    const {parentKey} = options;

    if (!parentKey.length && !obj) {
      return;
    }

    if (_.isObject(obj)) {
      _.forEach(obj, (el, key) => {
        this.traverseInput(el, callback, {parentKey: [...parentKey, key]});
      });
    } else {
      callback(parentKey, obj);
    }
  }

  // @example: ["root", "array", 1, "field"] => ["root", "array", "field"]
  removeIndexesFromQualifiedPath(fieldPath: FieldQualifiedPath): FieldQualifiedPath {
    return fieldPath.filter(part => typeof part !== 'number');
  }

  removeInternalPrefix(fieldPath: FieldQualifiedPath): FieldQualifiedPath {
    if (_.isString(fieldPath[0]) && ['input', 'filter', 'param'].includes(fieldPath[0])) {
      return fieldPath.slice(1);
    }

    return fieldPath;
  }

  /**
   * For different steps recipe config data is located in different places.
   * Path to input can be 'step.input', 'step.filter', 'step.param' or just 'step'.
   * This function adds necessary prefix depending on step keyword so we able
   * to get correct input from StepHelper.getStepInput.
   */
  addStepKeywordPrefix(fieldPath: FieldQualifiedPath, step: RecipeStep | RecipeParametersStep): FieldQualifiedPath {
    if (step instanceof RecipeParametersStep) {
      return ['param', ...fieldPath];
    }

    if (['catch', 'trigger'].includes(step.keyword) && fieldPath[0] === 'conditions') {
      return ['filter', ...fieldPath];
    }

    if (step.keyword === 'foreach' || (step.keyword === 'action' && fieldPath[0] === 'external_input_definition')) {
      return fieldPath;
    }

    return ['input', ...fieldPath];
  }

  determineWidgetType(field: SchemaField, isToggleItem = false): FormFieldWidgetType {
    if (this.isListField(field)) {
      return 'list';
    } else if (this.isComplexField(field)) {
      return 'object';
    } else if (this.isToggleField(field) && !isToggleItem) {
      return 'toggle';
    } else if (this.isPrimitiveListField(field)) {
      return 'text';
    } else if (this.isTextField(field)) {
      if (field.disable_formula) {
        return field.control_type === 'text-area' ? 'plain-text-area' : 'plain-text';
      } else {
        return 'text';
      }
    } else {
      return field.control_type;
    }
  }

  determineListFieldMode(field: ListField, value: FieldValue): ListField['list_mode'] {
    const defaultMode = field.list_mode || DEFAULT_LIST_FIELD_MODE;

    if (field.list_mode_toggle === false) {
      return defaultMode;
    }

    if (_.isArray(value)) {
      return 'static';
    } else if (_.isPlainObject(value)) {
      return 'dynamic';
    } else {
      return defaultMode;
    }
  }

  makeArraySourceField(parentField: SchemaField): SchemaField {
    return this.defineParent(
      {
        name: ARRAY_SOURCE_FIELD_NAME,
        type: 'string',
        control_type: 'list',
        optional: true,
        label: `${parentField.label} source list`,
        hint: "Input a list datapill. <a href='https://docs.workato.com/features/list-management.html#list-batch-action' target='_blank'>Learn more about list input</a>",
        drag_list: true,
        enforce_template_mode: true,
      },
      parentField,
    ).field;
  }

  createItemTemplateCompiler(
    fieldGetter: (itemIndex: number, fieldPath: FieldQualifiedPath) => SchemaField | undefined,
    inputGetter: () => FieldsInput,
  ): TemplateCompiler {
    const allowedDataProps: Array<keyof ListFieldItemTemplateData> = ['itemIndex'];
    const isPicklist = (options: SelectFieldWithOptions['options']): options is Picklist | undefined =>
      !options?.length || Array.isArray(options[0]);

    return new TemplateCompiler({
      allowedDataProps,
      helpers: {
        ...helpers,

        getItemFieldValue: (
          itemIndex: number,
          fieldPath: SchemaField['name'] | FieldQualifiedPath,
        ): FieldValue | undefined => {
          const field = fieldGetter(itemIndex, _.castArray(fieldPath));

          return field ? this.getValue(field, inputGetter()) : undefined;
        },

        getSelectedOptionTitleForItemField: (
          itemIndex: number,
          fieldPath: SchemaField['name'] | FieldQualifiedPath,
        ): string | undefined => {
          const field = fieldGetter(itemIndex, _.castArray(fieldPath));

          if (field && this.isSelectField(field) && this.isFieldWithOptions(field) && isPicklist(field.options)) {
            const value = this.getValue(field, inputGetter());
            const selectedOption = field.options?.find(option => option[1] === value);

            return selectedOption?.[0];
          }
        },
      },
    });
  }

  stringifyQualifiedPath(path: FieldQualifiedPath): string {
    return JSON.stringify(path);
  }

  parseQualifiedPathString(pathString: string): FieldQualifiedPath {
    try {
      return JSON.parse(pathString);
    } catch {
      return [];
    }
  }

  /**
   * The legacy way to represent qualified path by a string with field names joined with dot
   * is still used for backwards compatibility of some data saved in existing recipes, e.g.
   * toggleCfg, dynamicPicklistSelection, visible_config_fields and hidden_config_fields
   * For new features please use stringifyQualifiedPath
   * @deprecated
   * FIXME: https://workato.atlassian.net/browse/UI-1411
   * FIXME: https://workato.atlassian.net/browse/UI-1412
   * FIXME: https://workato.atlassian.net/browse/UI-1413
   */
  joinPathByDot(path: FieldQualifiedPath): string {
    return path.join('.');
  }

  parseDotJoinedPath(path: string): FieldQualifiedPath {
    // The regexp matches all non-negative integers
    return path.split('.').map(element => (/^0$|^[1-9][0-9]*$/.test(element) ? Number(element) : element));
  }

  pathsAreEqual(path1: FieldQualifiedPath | undefined | null, path2: FieldQualifiedPath | undefined | null): boolean {
    return Array.isArray(path1) && Array.isArray(path2) && _.isEqual(path1, path2);
  }

  pathStartsWith(
    path: FieldQualifiedPath | undefined | null,
    fragment: FieldQualifiedPath | undefined | null,
  ): boolean {
    return (
      Array.isArray(path) && Array.isArray(fragment) && this.pathsAreEqual(path.slice(0, fragment.length), fragment)
    );
  }

  private evalExpression(field: SchemaField, type: ExpressionType, context: ExpressionContext): unknown {
    const expression = field[type];

    if (!expression) {
      return null;
    }

    const compiledExpressionKey = COMPILED_EXPRESSION_KEYS[type];

    if (!field[compiledExpressionKey]) {
      field[compiledExpressionKey] = this.compileExpression(type, expression, field.name);
    }

    const line = context.step || context.fieldContext;
    const input = context.input || context.step?.input || {};
    const parentInput: FieldsInput = field.parent ? _.get(input, field.parent) : input;
    const compiledExpression: ExpressionFunction<InternalExpressionContext> = field[compiledExpressionKey];

    try {
      return compiledExpression({input, parentInput, line, authUser: this.authUser});
    } catch (err) {
      this.log.error(`Error executing parsed "${type}" expression for "${field.name}" field:`, err);

      return null;
    }
  }

  private compileExpression(
    type: ExpressionType,
    expression: string,
    fieldName: SchemaField['name'],
  ): ExpressionFunction<InternalExpressionContext> {
    try {
      return fieldExpressionParser.compile(expression);
    } catch (err) {
      this.log.error(`Error compiling "${type}" expression "${expression}" for "${fieldName}" field:`, err);

      return _.constant(true);
    }
  }

  private buildBulkFieldEvent(fields: SchemaField[]): BulkFieldEvent {
    return new Map(fields.map(field => [field, {field, qualifiedPath: this.getQualifiedPath(field)}]));
  }
}
