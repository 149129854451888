<div class="app-mobile-sidebar" [wKeyboardNavigation]="{cyclic: true}">
  <div
    [@slideInOut]="isOpened ? 'expanded' : 'collapsed'"
    (@slideInOut.done)="handleAnimationEnd()"
    class="app-mobile-sidebar__wrapper"
  >
    <button
      class="app-mobile-sidebar__close-btn"
      type="button"
      (click)="closeSidebar()"
      wIconButton="close"
      wIconButtonAriaLabel="Close sidebar"
      [wIconButtonIconSize]="16"
    ></button>

    <div [@contentSwitch]="view" (@contentSwitch.done)="handleAnimationEnd()" class="app-mobile-sidebar__panel">
      @switch (view) {
        @case ('main') {
          <div class="app-mobile-sidebar__view">
            <div>
              <div class="app-mobile-sidebar__section-title">Workspace</div>

              <w-app-mobile-sidebar-button
                type="forward"
                [disabled]="!hasMultipleOptions(workspaceOptions)"
                ariaLabel="Open workspace picker"
                (click)="setView('workspace')"
              >
                <w-app-context-switcher-icon type="workspace" [value]="currentWorkspace" />
                <span class="app-mobile-sidebar__button-title">{{ currentWorkspace.name }}</span>
              </w-app-mobile-sidebar-button>

              @if (hasProductSwitcher) {
                <hr class="app-mobile-sidebar__separator" />
                <div class="app-mobile-sidebar__section-title">Product</div>

                <w-app-mobile-sidebar-button
                  type="forward"
                  [disabled]="!hasMultipleOptions(productOptions)"
                  ariaLabel="Open product picker"
                  (click)="setView('product')"
                >
                  <w-app-context-switcher-icon type="product" [value]="currentProduct" />
                  <span class="app-mobile-sidebar__button-title">{{ currentProduct.name }}</span>
                </w-app-mobile-sidebar-button>
              }

              @if (hasEnvironmentSwitcher) {
                <hr class="app-mobile-sidebar__separator" />
                <div class="app-mobile-sidebar__section-title">Environment</div>

                <w-app-mobile-sidebar-button
                  type="forward"
                  [disabled]="!hasMultipleOptions(environmentOptions)"
                  ariaLabel="Open environment picker"
                  (click)="setView('environment')"
                >
                  <w-app-context-switcher-icon type="environment" [value]="currentEnvironment" />
                  <span class="app-mobile-sidebar__button-title">{{ currentEnvironment.name }}</span>
                </w-app-mobile-sidebar-button>
              }
            </div>

            <w-app-user-menu class="app-mobile-sidebar__bottom-menu" (linkClick)="closeSidebar()" />
          </div>
        }
        @case ('workspace') {
          <div class="app-mobile-sidebar__view">
            <w-app-mobile-sidebar-button type="back" ariaLabel="Back to mobile sidebar" (click)="goBack()">
              Workspace
            </w-app-mobile-sidebar-button>

            <hr class="app-mobile-sidebar__separator app-mobile-sidebar__separator_large" />

            <w-app-menu
              entityLabel="workspace"
              [options]="workspaceOptions"
              [selectedValue]="currentWorkspaceId"
              (selectedValueChange)="switchWorkspace($event); closeSidebar()"
            >
              <ng-template let-item [wAppMenuItemIcon]="workspaceOptions">
                <w-app-context-switcher-icon type="workspace" [value]="item.context" />
              </ng-template>
            </w-app-menu>
          </div>
        }
        @case ('product') {
          <div class="app-mobile-sidebar__view">
            <w-app-mobile-sidebar-button type="back" ariaLabel="Back to mobile sidebar" (click)="goBack()">
              Product
            </w-app-mobile-sidebar-button>

            <hr class="app-mobile-sidebar__separator app-mobile-sidebar__separator_large" />

            <w-app-menu
              [options]="productOptions"
              [selectedValue]="currentProductId"
              (selectedValueChange)="switchProduct($event); closeSidebar()"
            >
              <ng-template let-item [wAppMenuItemIcon]="productOptions">
                <w-app-context-switcher-icon type="product" [value]="item.context" />
              </ng-template>
            </w-app-menu>
          </div>
        }
        @case ('environment') {
          <div class="app-mobile-sidebar__view">
            <w-app-mobile-sidebar-button type="back" ariaLabel="Back to mobile sidebar" (click)="goBack()">
              Environment
            </w-app-mobile-sidebar-button>

            <hr class="app-mobile-sidebar__separator app-mobile-sidebar__separator_large" />

            <w-app-menu
              [options]="environmentOptions"
              [selectedValue]="currentEnvironmentId"
              (selectedValueChange)="switchEnvironment($event); closeSidebar()"
            >
              <ng-template let-item [wAppMenuItemIcon]="environmentOptions">
                <w-app-context-switcher-icon type="environment" [value]="item.context" />
              </ng-template>
            </w-app-menu>
          </div>
        }
      }
    </div>
  </div>
</div>
