<button
  class="app-menu-item"
  [class.app-menu-item_disallowed]="option.locked && !option.lockedUrl"
  type="button"
  [disabled]="option.locked"
  [attr.aria-label]="'Header menu item'"
  (click)="selectOption()"
  wKeyboardNavigationItem
  wTooltipOriginType="pointer"
  wTooltipPlacement="bottom"
  [wTooltipArrow]="true"
  [wTooltip]="option.lockedTooltip"
  [wTooltipDisabled]="!option.locked"
>
  @if (iconTemplate) {
    <div class="app-menu-item__icon">
      <ng-container [ngTemplateOutlet]="iconTemplate" [ngTemplateOutletContext]="{$implicit: option}" />
    </div>
  }
  <span class="app-menu-item__title">
    <w-trimmed-text [text]="option.title" [highlightTerm]="highlight" [lineCount]="3" />
    @if (option.locked) {
      <w-svg-icon class="app-menu-item__lock" iconId="lock" [size]="10" />
    }
  </span>

  @if (selected) {
    <w-svg-icon class="app-menu-item__aside app-menu-item__checkmark" iconId="check" [size]="12" />
  } @else if (option.locked && option.lockedUrl) {
    <a
      class="app-menu-item__locked-link app-menu-item__aside"
      [href]="option.lockedUrl"
      target="_blank"
      wKeyboardNavigationItem
    >
      Discover
      <w-svg-icon iconId="arrow-corner" [size]="6" />
    </a>
  }
</button>
