import _ from 'lodash';
import {Subject} from 'rxjs';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {HttpResource, Request} from '@shared/services/http-resource';
import {OptionalProps} from '@shared/types/lib';

import {delay} from '../../utils/delay';
import {
  Adapter,
  AsyncPicklistFor,
  FieldsInput,
  Genie,
  Hash,
  ListFieldSchema,
  Operation,
  OutputSchemaField,
  PagedResponse,
  Recipe,
  RecipeStep,
  RecipeStepHelp,
  SampleData,
  SchemaField,
  SelectLikeControlType,
} from '../../types';
import {CustomOauthKey} from '../custom-oauth-keys/custom-oauth-keys.types';
import {Folder} from '../../modules/folders/folders.types';

import {
  Connection,
  ConnectionChange,
  ConnectionDebugTrace,
  ConnectionLostInfo,
  ConnectionValidationResponse,
  ExtendedConnection,
} from './connections.types';
import {ConnectionHelper} from './connection-helper.service';

export type TestConnectionData = OptionalProps<ExtendedConnection, 'id'>;

export type CreateConnectionData = Omit<ExtendedConnection, 'id'>;

export type UpdateConnectionData = Pick<ExtendedConnection, 'id'> & Partial<Omit<ExtendedConnection, 'id'>>;

export interface ExtendedSchemaResult {
  title: string;
  description: string;
  help: RecipeStepHelp;
  input: SchemaField[];
  output: OutputSchemaField[];
}

interface TestResult {
  status: 'success' | 'exception';
  message: string;
  account?: ExtendedConnection;
}

export interface SampleRequestResult<TBodyType extends string | object = string> {
  status_code?: number;
  headers?: Hash<string>;
  body?: TBodyType;
}

interface OAuthResponse {
  status: 'success' | 'exception';
  account: ExtendedConnection;
  message?: string;
  message_details?: any;
  type?: string;
}

export interface PicklistParams {
  id: number | string;
  field: SchemaField;
  pick_list_params?: object;
  flow_id?: Recipe['id'];
}

export interface ExtendedSchemaParams {
  id: Connection['id'] | Connection['provider'];
  flow_id?: Recipe['id'];
  operation_name?: RecipeStep['name'];
  input?: RecipeStep['input'];
  dynamic_pick_list_selection?: RecipeStep['dynamicPickListSelection'];
  depends_on?: FieldsInput;
  init_connection?: boolean;
  list_schema?: ListFieldSchema;
  staging?: boolean;
  /*
   * Allows to specify data to request. May be used for optimization purposes:
   *   'input' - request extended input schema
   *   'output' - request extended output schema
   *   'help' - request step help as it may be dynamic (depend on inputs etc.)
   */
  only?: ExtendedSchemaRequestDataKey[];
}

export const EXTENDED_SCHEMA_REQUEST_DATA_KEYS = ['input', 'output', 'help'] as const;

export type ExtendedSchemaRequestDataKey = (typeof EXTENDED_SCHEMA_REQUEST_DATA_KEYS)[number];

interface FetchStepDescriptionData {
  provider: RecipeStep['provider'];
  operation_name?: RecipeStep['name'];
  input?: RecipeStep['input'];
  dynamic_pick_list_selection?: RecipeStep['dynamicPickListSelection'];
}

interface FetchStepDescriptionsParams {
  items: FetchStepDescriptionData[];
}

export interface GetSampleDataParams {
  id: Connection['id'] | Adapter['name'];
  type: string;
  name?: string;
  input: any;
  schema: any;
  staging?: boolean;
}

export interface CheckOauthConditionResponse {
  need_oauth: boolean;
  noopener: boolean;
}

interface DestroyParams {
  id: Connection['id'];
}

interface DisconnectParams {
  id: Connection['id'];
}

interface LoadingState {
  byAdapter: Map<Connection['provider'], Promise<void>>;
  byId: Map<Connection['id'], Promise<void>>;
}

interface ConnectionRequestParams {
  with_children?: boolean;
}

interface ConnectionRequestByAdaptersParams extends ConnectionRequestParams {
  'adapter[]'?: Array<Connection['provider']>;
  'q[authorization_status_eq]'?: 'success';
  'q[name_cont]'?: string;
  page?: number;
  per_page?: number;
  genie_id?: Genie['id'];
}

interface ConnectionRequestByIdsParams extends ConnectionRequestParams {
  'id[]'?: Array<Connection['id']>;
}

export function isFailedValidation(response: ConnectionValidationResponse): response is ConnectionLostInfo {
  return !(response as any).success;
}

const TEST_POLL_TIMEOUT = 120 * 1000;

@Injectable({
  providedIn: 'root',
})
export class Connections {
  connectionChanged = new Subject<ConnectionChange>();
  connectionsLoaded = new Subject<void>();
  connectionSwitched = new Subject<void>();

  private loadingState: LoadingState = {
    byAdapter: new Map(),
    byId: new Map(),
  };

  private connections = new Map<Connection['id'], Connection>();

  private resource: HttpResource;
  private webApiResource: HttpResource;

  constructor(
    private connectionHelper: ConnectionHelper,
    private http: HttpClient,
  ) {
    this.resource = new HttpResource(this.http, {
      url: '/connections/{{id}}/{{action}}.json',
    });
    this.webApiResource = new HttpResource(this.http, {
      url: '/web_api/connections/{{id}}/{{action}}.json',
    });
  }

  async get(id: Connection['id']): Promise<ExtendedConnection> {
    const connection = await this.resource.get<ExtendedConnection>({id});

    this.addOrUpdate(connection);

    return connection;
  }

  async test(connectionData: TestConnectionData, staging?: boolean, genieId?: Genie['id']): Promise<TestResult> {
    let result = await this.resource.post<TestResult>(
      {action: 'test_async'},
      {account_data: connectionData, staging, genie_id: genieId},
    );

    const id = result.account?.id || connectionData.id;
    const startedAt = Date.now();

    while (result.status !== 'success' && result.status !== 'exception') {
      if (Date.now() - startedAt > TEST_POLL_TIMEOUT) {
        return Promise.reject('Connection test timeout');
      }

      await delay(2000);

      result = await this.resource.get({action: 'test_result', id});
    }

    if (result.account) {
      this.addOrUpdate(result.account);
    }

    return result;
  }

  async testAction(
    connectionId: Connection['id'],
    actionName: Operation['name'],
    input: FieldsInput,
  ): Promise<SampleRequestResult<object | string>> {
    return this.resource.post({id: connectionId, action: 'test_action'}, {name: actionName, input});
  }

  processOAuthResponse(response: OAuthResponse): ExtendedConnection {
    if (response.status === 'exception') {
      response.account.authorization_status = 'exception';
      response.account.authorization_error = response.message;
      response.account.authorization_error_details = _.assign(response.message_details, {type: response.type});
    }

    this.addOrUpdate(response.account);

    return response.account;
  }

  checkOauthCondition(connectionData: TestConnectionData, useStaging?: boolean): Request<CheckOauthConditionResponse> {
    return this.resource.post<CheckOauthConditionResponse>(
      {action: 'check_oauth_condition'},
      {account_data: connectionData, staging: useStaging},
    );
  }

  async loadById(id: Connection['id'], force = false): Promise<ExtendedConnection> {
    return (await this.loadByIds([id], force))[0];
  }

  async loadByIds(ids: Array<Connection['id']>, force = false): Promise<ExtendedConnection[]> {
    const loadingIds = this.loadingState.byId;
    const idsToLoad = ids.filter(id => {
      if (loadingIds.has(id)) {
        return false;
      }

      const connection = this.connections.get(id);

      return !connection || !this.connectionHelper.isExtendedConnection(connection) || force;
    });

    if (idsToLoad.length) {
      const loadPromise = this._loadByIds({'id[]': idsToLoad})
        .then(connections => this.addOrUpdate(...connections))
        .finally(() => idsToLoad.forEach(id => loadingIds.delete(id)));

      idsToLoad.forEach(id => loadingIds.set(id, loadPromise));
    }

    await Promise.all(ids.map(id => loadingIds.get(id))).finally(() => this.connectionsLoaded.next());

    return this.list().filter(({id}) => ids.includes(id)) as ExtendedConnection[];
  }

  async loadByAdapter(adapter: Connection['provider'], force = false, genieId?: Genie['id']): Promise<Connection[]> {
    return this.loadByAdapters([adapter], force, genieId);
  }

  async loadByAdapters(
    adapters: Array<Connection['provider']>,
    force = false,
    genieId?: Genie['id'],
  ): Promise<Connection[]> {
    const loadingState = this.loadingState.byAdapter;
    const adaptersToLoad = adapters.filter(
      (adapterName): adapterName is Adapter['name'] => !loadingState.has(adapterName) || force,
    );

    if (adaptersToLoad.length) {
      const promise = this._loadByAdapters({'adapter[]': adaptersToLoad, genie_id: genieId})
        .then(response => {
          adaptersToLoad.forEach(adapterName => {
            if (loadingState.get(adapterName) === promise && force) {
              this.deleteForAdapter(adapterName);
            }
          });
          this.addOrUpdate(...response.items);
        })
        .catch(err => {
          adaptersToLoad.forEach(adapterName => {
            if (loadingState.get(adapterName) === promise) {
              loadingState.delete(adapterName);
              throw err;
            }
          });
        });

      adaptersToLoad.forEach(adapterName => {
        loadingState.set(adapterName, promise);
      });
    }

    await Promise.all(adapters.map(adapterName => loadingState.get(adapterName))).finally(() =>
      this.connectionsLoaded.next(),
    );

    return this.list().filter(({provider}) => adapters.includes(provider));
  }

  async loadAll(withChildren = false): Promise<Connection[]> {
    const response = await this._loadAll({with_children: withChildren});

    this.addOrUpdate(...response.items);
    this.connectionsLoaded.next();

    return response.items;
  }

  async loadPicklist<TControlType extends SelectLikeControlType, TSupportsLegacy extends boolean = true>(
    params: PicklistParams,
  ): Promise<AsyncPicklistFor<TControlType, TSupportsLegacy> | null> {
    return this.resource.post({id: params.id, action: 'pick_list'}, _.omit(params, 'id'));
  }

  loadExtendedSchema(params: ExtendedSchemaParams): Request<ExtendedSchemaResult> {
    return this.resource.post({id: params.id, action: 'extended_schema'}, _.omit(params, 'id'));
  }

  loadDescriptions(data: FetchStepDescriptionsParams): Request<Array<RecipeStep['description']>> {
    return this.resource.post({action: 'descriptions'}, data);
  }

  async create(
    connectionData: CreateConnectionData,
    useStaging?: boolean,
    genieId?: Genie['id'],
  ): Promise<ExtendedConnection> {
    const connection = await this.resource.create<ExtendedConnection>({
      shared_account: connectionData,
      staging: useStaging,
      genie_id: genieId,
    });

    this.addOrUpdate(connection);

    return connection;
  }

  async update(connectionData: UpdateConnectionData): Promise<ExtendedConnection> {
    const connection = await this.resource.update<ExtendedConnection>(
      {id: connectionData.id},
      {shared_account: connectionData},
    );

    this.addOrUpdate(connection);

    return connection;
  }

  async moveToFolder(connectionId: Connection['id'], folderId: Folder['id']): Promise<boolean> {
    const success = await this.resource.update<boolean>(
      {id: connectionId, action: 'update_folder'},
      {folder_id: folderId},
    );

    if (success && this.connections.has(connectionId)) {
      this.connections.get(connectionId)!.folder_id = folderId;
    }

    return success;
  }

  async getSampleData(params: GetSampleDataParams): Promise<SampleData | null> {
    try {
      return await this.resource.post({id: params.id, action: 'sample_output'}, _.omit(params, 'id'));
    } catch {
      return null;
    }
  }

  async destroy(params: DestroyParams): Promise<void> {
    await this.resource.delete({id: params.id});
    this.loadingState.byId.delete(params.id);
    this.connections.delete(params.id);
  }

  async disconnect(params: DisconnectParams): Promise<ExtendedConnection> {
    const connection = await this.resource.update<ExtendedConnection>({id: params.id, action: 'disconnect'});

    this.addOrUpdate(connection);

    return connection;
  }

  async debugTrace(id: Connection['id']): Promise<ConnectionDebugTrace> {
    return this.webApiResource.get({id, action: 'debug_trace'});
  }

  validate(id: Connection['id']): Request<ConnectionValidationResponse> {
    return this.webApiResource.get({id, action: 'validate_connection'});
  }

  build(
    provider: Adapter['name'],
    folderId?: Connection['folder_id'] | null,
    customOauthKeyId?: CustomOauthKey['id'],
    genie?: Pick<Genie, 'id' | 'name'>,
  ): Connection {
    const connection: Omit<Connection, 'id'> = {
      name: '',
      provider,
      identity: null,
      recipe_count: 0,
      proxy_api_endpoints_count: 0,
      alr_connection: false,
      secrets_manager_connection: false,
      traffic_mirroring_connection: false,
    };

    if (folderId) {
      connection.folder_id = folderId;
    }

    if (customOauthKeyId) {
      connection.custom_oauth_key_id = customOauthKeyId;
    }

    if (genie) {
      connection.genie = genie;
    }

    return connection as Connection;
  }

  find(id: Connection['id'] | undefined | null): Connection | undefined {
    return typeof id === 'number' ? this.connections.get(id) : undefined;
  }

  findByProvider(provider: Connection['provider']): Connection[] {
    return this.list().filter(connection => connection.provider === provider);
  }

  findConnectedByProvider(provider: Connection['provider']): Connection[] {
    return this.list().filter(
      connection => connection.provider === provider && this.connectionHelper.isConnected(connection),
    );
  }

  connected(id: Connection['id'] | undefined): boolean {
    const connection = this.find(id);

    return Boolean(connection && this.connectionHelper.isConnected(connection));
  }

  list(): Connection[] {
    return [...this.connections.values()];
  }

  replaceWith(newConnections: Connection[]) {
    this.connections.clear();
    this.loadingState.byId.clear();
    this.loadingState.byAdapter.clear();
    this.addOrUpdate(...newConnections);
  }

  notifyConnectionCreated(connection: Connection) {
    this.connectionChanged.next({type: 'created', connection});
  }

  notifyConnectionChanged(connection: Connection) {
    if (this.connectionHelper.isConnectionLost(connection)) {
      this.connectionChanged.next({type: 'lost', connection});
    } else if (this.connectionHelper.isConnected(connection)) {
      this.connectionChanged.next({type: 'connected', connection});
    } else {
      this.connectionChanged.next({type: 'invalid', connection});
    }
  }

  private addOrUpdate(...connections: Array<Connection | ExtendedConnection>) {
    connections.forEach(newConnection => {
      const oldConnection = this.connections.get(newConnection.id);

      if (oldConnection) {
        // A lot of our code depends on connections being mutated in-place, keeping the reference.
        Object.assign(oldConnection, newConnection);
      } else {
        this.connections.set(newConnection.id, newConnection);
      }
    });
  }

  private deleteForAdapter(name: Adapter['name']) {
    for (const [id, connection] of this.connections.entries()) {
      if (connection.provider === name) {
        this.connections.delete(id);
      }
    }
  }

  private _loadByAdapters(params?: ConnectionRequestByAdaptersParams): Request<PagedResponse<Connection>> {
    return this.resource.getAll({query: params});
  }

  private _loadByIds(params?: ConnectionRequestByIdsParams): Request<ExtendedConnection[]> {
    return this.resource.get({action: 'extended'}, {query: params});
  }

  private _loadAll(params?: ConnectionRequestParams): Request<PagedResponse<Connection>> {
    return this.resource.getAll({query: params});
  }
}
