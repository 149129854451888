import {Directive} from '@angular/core';
import {COMPOSITION_BUFFER_MODE} from '@angular/forms';

/* By default `(ngModelChange)` and `formControl.valueChanges` doesn't emit values during "character(s) composition".
 *
 * "Character composition" happens when we want to insert characters that are not present on the Latin keyboard.
 * For example, Korean characters. To insert them, we need to have the Korean keyboard enabled.
 * So, by pressing a key with a Latin character, we start the composition of a Korean character (it may consist of one or more Latin characters).
 *
 * This directive overrides `FormControl` default behaviour by providing a `false` value for the `COMPOSITION_BUFFER_MODE` token.
 * Therefore, the value is updated while composition updates runs.
 * It is useful for cases where search should be kept in sync even when composition is running.
 */
@Directive({
  selector: '[wDisableCompositionBuffer]',
  standalone: true,
  providers: [{provide: COMPOSITION_BUFFER_MODE, useValue: false}],
})
export class DisableCompositionBufferDirective {}
