import {ChangeDetectionStrategy, Component} from '@angular/core';

import {AbstractBannerComponent} from '../abstract-banner.component';

@Component({
  selector: 'w-configure-2fa-banner',
  templateUrl: './configure-2fa-banner.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Configure2faBannerComponent extends AbstractBannerComponent {}
