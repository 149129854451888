import {ChangeDetectionStrategy, Component, ViewEncapsulation} from '@angular/core';

import {KeyboardNavigationItemDirective} from '@shared/modules/keyboard-navigation/keyboard-navigation-item.directive';
import {KeyboardNavigationDirective} from '@shared/modules/keyboard-navigation/keyboard-navigation.directive';

import {UserAvatarModule} from '../../modules/user-avatar/user-avatar.module';
import {AuthUser} from '../../services/auth-user';
import {SharedModule} from '../../shared.module';
import {AppUserMenuComponent} from '../app-user-menu/app-user-menu.component';
import {AppHeaderDropdownComponent} from '../app-header-dropdown/app-header-dropdown.component';

@Component({
  selector: 'w-app-header-user',
  templateUrl: './app-header-user.component.html',
  styleUrl: './app-header-user.component.scss',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    SharedModule,
    UserAvatarModule,
    AppUserMenuComponent,
    AppHeaderDropdownComponent,
    KeyboardNavigationItemDirective,
    KeyboardNavigationDirective,
  ],
})
export class AppHeaderUserComponent {
  constructor(private authUser: AuthUser) {}

  get userName(): string {
    return this.authUser.logged_name;
  }

  get userAvatar(): string {
    return this.authUser.profile_image;
  }
}
