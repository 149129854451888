<div class="app-sidebar-oem-logo" [class.app-sidebar-oem-logo_expanded]="isExpanded">
  <div class="app-sidebar-oem-logo__outer-wrapper">
    <div class="app-sidebar-oem-logo__inner-wrapper" (wElementResize)="handleLogoOffsetChange($event.width)">
      @if (oemLogoSmall) {
        <img
          class="app-sidebar-oem-logo__img app-sidebar-oem-logo__img_small"
          [src]="oemLogoSmall"
          [alt]="oemCompany"
        />
      }
      @if (oemLogo) {
        <img class="app-sidebar-oem-logo__img app-sidebar-oem-logo__img_large" [src]="oemLogo" [alt]="oemCompany" />
      }
    </div>
  </div>
</div>
