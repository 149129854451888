import _ from 'lodash';
import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FixedHeaderService {
  height$ = new BehaviorSubject<number>(0);

  // Array of headers that may *possibly* be fixed/sticky
  private headers: Array<HTMLElement | string> = [];

  add(elem: HTMLElement): void {
    this.headers.push(elem);
    this.notifyFixedHeaderHeight();
  }

  remove(elem: HTMLElement): void {
    _.pull(this.headers, elem);
    this.notifyFixedHeaderHeight();
  }

  notifyFixedHeaderHeight() {
    const height = this.getFixedHeaderHeight();

    document.documentElement.style.setProperty('--app-layout-sticky-area-height', `${height}px`);
    this.height$.next(height);
  }

  getFixedHeaderHeight(): number {
    let height = 0;

    for (const header of this.headers) {
      const elem = typeof header === 'string' ? document.querySelector<HTMLElement>(header) : header;

      if (!elem) {
        continue;
      }

      const lastFixedElement = this.getFixedOrStickyParentElement(elem);

      if (!lastFixedElement) {
        continue;
      }

      const style = window.getComputedStyle(lastFixedElement);

      /*
       * Seems like sometimes `getComputedStyle` may return `null`
       * Fixes https://sentry.awsprod.workato.com/workato/workato-frontend/issues/94843/?referrer=slack
       */
      if (!style) {
        continue;
      }

      if (style.position === 'fixed' || style.position === 'sticky') {
        height = Math.max(height, lastFixedElement.offsetHeight + parseInt(style.top, 10));
      }
    }

    return height;
  }

  private getFixedOrStickyParentElement(targetElement: HTMLElement): HTMLElement | null {
    let element: HTMLElement | null = targetElement;
    let lastFixedElement: HTMLElement | null = null;

    while (element) {
      const style = window.getComputedStyle(element);

      if (style?.position === 'fixed' || style?.position === 'sticky') {
        lastFixedElement = element;
      }

      element = element.parentElement;
    }

    return lastFixedElement;
  }
}
